<template>
  <v-app-bar class="top-nav">
    <img class="logo" src="../images/logo.png" alt="Logo">
    <!--/// Doctors Menu Start Here ///-->
    <v-menu class="custom-menu">
      <template v-slot:activator="{ props }">
        <v-btn
            class="menu-btn"
            v-bind="props"
        >
          Doctors
          <v-icon class="mdi mdi-chevron-down"></v-icon>
        </v-btn>
      </template>
      <v-list class="pb-0">
        <v-container>
          <v-list-item>
            <h4>Choose a doctor</h4>
            <a href="javascript:" class="d-block mb-7">
              View all specialties
              <v-icon class="mdi mdi-chevron-right"></v-icon>
            </a>
            <div class="scroll-wrapper">
              <!--/// Find Doctor By Speciality Start Here ///-->
              <div class="details-box">
                <h3>Find doctor by speciality</h3>
                <v-menu
                    :location="location"
                    open-on-hover
                    class="menu-hover"
                >
                  <template v-slot:activator="{ props }">
                    <v-btn
                        class="menu-btn ma-0"
                        v-bind="props"
                    >
                      Dermatologist
                      <v-icon class="mdi mdi-chevron-down"></v-icon>
                    </v-btn>
                  </template>
                  <v-list>
                    <v-list-item>
                      <div class="Dermatologist-details">
                        <a href="javascript:">Dermatologist in Lahore</a>
                        <a href="javascript:">Dermatologist in Islamabad</a>
                        <a href="javascript:">Dermatologist in Rawalpindi</a>
                        <a href="javascript:">Dermatologist in Karachi</a>
                      </div>
                    </v-list-item>
                  </v-list>
                </v-menu>
                <!--///-->
                <v-menu
                    :location="location"
                    open-on-hover
                    class="menu-hover"
                >
                  <template v-slot:activator="{ props }">
                    <v-btn
                        class="menu-btn ma-0"
                        v-bind="props"
                    >
                      Gynecologist
                      <v-icon class="mdi mdi-chevron-down"></v-icon>
                    </v-btn>
                  </template>
                  <v-list>
                    <v-list-item>
                      <div class="Dermatologist-details">
                        <a href="javascript:">Gynecologist in Lahore</a>
                        <a href="javascript:">Gynecologist in Islamabad</a>
                        <a href="javascript:">Gynecologist in Rawalpindi</a>
                        <a href="javascript:">Gynecologist in Karachi</a>
                      </div>
                    </v-list-item>
                  </v-list>
                </v-menu>
                <!--///-->
                <v-menu
                    :location="location"
                    open-on-hover
                    class="menu-hover"
                >
                  <template v-slot:activator="{ props }">
                    <v-btn
                        class="menu-btn ma-0"
                        v-bind="props"
                    >
                      Psychiatrist
                      <v-icon class="mdi mdi-chevron-down"></v-icon>
                    </v-btn>
                  </template>
                  <v-list>
                    <v-list-item>
                      <div class="Dermatologist-details">
                        <a href="javascript:">Psychiatrist in Lahore</a>
                        <a href="javascript:">Psychiatrist in Islamabad</a>
                        <a href="javascript:">Psychiatrist in Rawalpindi</a>
                        <a href="javascript:">Psychiatrist in Karachi</a>
                      </div>
                    </v-list-item>
                  </v-list>
                </v-menu>
                <!--///-->
                <v-menu
                    :location="location"
                    open-on-hover
                    class="menu-hover"
                >
                  <template v-slot:activator="{ props }">
                    <v-btn
                        class="menu-btn ma-0"
                        v-bind="props"
                    >
                      Urologist
                      <v-icon class="mdi mdi-chevron-down"></v-icon>
                    </v-btn>
                  </template>
                  <v-list>
                    <v-list-item>
                      <div class="Dermatologist-details">
                        <a href="javascript:">Urologist in Lahore</a>
                        <a href="javascript:">Urologist in Islamabad</a>
                        <a href="javascript:">Urologist in Rawalpindi</a>
                        <a href="javascript:">Urologist in Karachi</a>
                      </div>
                    </v-list-item>
                  </v-list>
                </v-menu>
                <!--///-->
                <v-menu
                    :location="location"
                    open-on-hover
                    class="menu-hover"
                >
                  <template v-slot:activator="{ props }">
                    <v-btn
                        class="menu-btn ma-0"
                        v-bind="props"
                    >
                      Sexologist
                      <v-icon class="mdi mdi-chevron-down"></v-icon>
                    </v-btn>
                  </template>
                  <v-list>
                    <v-list-item>
                      <div class="Dermatologist-details">
                        <a href="javascript:">Sexologist in Lahore</a>
                        <a href="javascript:">Sexologist in Islamabad</a>
                        <a href="javascript:">Sexologist in Rawalpindi</a>
                        <a href="javascript:">Sexologist in Karachi</a>
                      </div>
                    </v-list-item>
                  </v-list>
                </v-menu>
                <!--///-->
                <v-menu
                    :location="location"
                    open-on-hover
                    class="menu-hover"
                >
                  <template v-slot:activator="{ props }">
                    <v-btn
                        class="menu-btn ma-0"
                        v-bind="props"
                    >
                      ENT Specialist
                      <v-icon class="mdi mdi-chevron-down"></v-icon>
                    </v-btn>
                  </template>
                  <v-list>
                    <v-list-item>
                      <div class="Dermatologist-details">
                        <a href="javascript:">ENT Specialist in Lahore</a>
                        <a href="javascript:">ENT Specialist in Islamabad</a>
                        <a href="javascript:">ENT Specialist in Rawalpindi</a>
                        <a href="javascript:">ENT Specialist in Karachi</a>
                      </div>
                    </v-list-item>
                  </v-list>
                </v-menu>
                <!--///-->
                <v-menu
                    :location="location"
                    open-on-hover
                    class="menu-hover"
                >
                  <template v-slot:activator="{ props }">
                    <v-btn
                        class="menu-btn ma-0"
                        v-bind="props"
                    >
                      Eye Specialist
                      <v-icon class="mdi mdi-chevron-down"></v-icon>
                    </v-btn>
                  </template>
                  <v-list>
                    <v-list-item>
                      <div class="Dermatologist-details">
                        <a href="javascript:">Eye Specialist in Lahore</a>
                        <a href="javascript:">Eye Specialist in Islamabad</a>
                        <a href="javascript:">Eye Specialist in Rawalpindi</a>
                        <a href="javascript:">Eye Specialist in Karachi</a>
                      </div>
                    </v-list-item>
                  </v-list>
                </v-menu>
                <!--///-->
                <v-menu
                    :location="location"
                    open-on-hover
                    class="menu-hover"
                >
                  <template v-slot:activator="{ props }">
                    <v-btn
                        class="menu-btn ma-0"
                        v-bind="props"
                    >
                      Neurologist
                      <v-icon class="mdi mdi-chevron-down"></v-icon>
                    </v-btn>
                  </template>
                  <v-list>
                    <v-list-item>
                      <div class="Dermatologist-details">
                        <a href="javascript:">Neurologist in Lahore</a>
                        <a href="javascript:">Neurologist in Islamabad</a>
                        <a href="javascript:">Neurologist in Rawalpindi</a>
                        <a href="javascript:">Neurologist in Karachi</a>
                      </div>
                    </v-list-item>
                  </v-list>
                </v-menu>
                <!--///-->
                <v-menu
                    :location="location"
                    open-on-hover
                    class="menu-hover"
                >
                  <template v-slot:activator="{ props }">
                    <v-btn
                        class="menu-btn ma-0"
                        v-bind="props"
                    >
                      Orthopedic Surgeon
                      <v-icon class="mdi mdi-chevron-down"></v-icon>
                    </v-btn>
                  </template>
                  <v-list>
                    <v-list-item>
                      <div class="Dermatologist-details">
                        <a href="javascript:">Orthopedic Surgeon in Lahore</a>
                        <a href="javascript:">Orthopedic Surgeon in Islamabad</a>
                        <a href="javascript:">Orthopedic Surgeon in Rawalpindi</a>
                        <a href="javascript:">Orthopedic Surgeon in Karachi</a>
                      </div>
                    </v-list-item>
                  </v-list>
                </v-menu>
                <!--///-->
                <v-menu
                    :location="location"
                    open-on-hover
                    class="menu-hover"
                >
                  <template v-slot:activator="{ props }">
                    <v-btn
                        class="menu-btn ma-0"
                        v-bind="props"
                    >
                      Child Specialist
                      <v-icon class="mdi mdi-chevron-down"></v-icon>
                    </v-btn>
                  </template>
                  <v-list>
                    <v-list-item>
                      <div class="Dermatologist-details">
                        <a href="javascript:">Child Specialist in Lahore</a>
                        <a href="javascript:">Child Specialist in Islamabad</a>
                        <a href="javascript:">Child Specialist in Rawalpindi</a>
                        <a href="javascript:">Child Specialist in Karachi</a>
                      </div>
                    </v-list-item>
                  </v-list>
                </v-menu>
                <!--///-->
              </div>
              <!--/// Find Doctor By Speciality End Here ///-->

              <!--/// Find Doctor By Treatment Start Here ///-->
              <div class="details-box">
                <h3>Find doctor by Treatment</h3>
                <v-menu
                    :location="location"
                    open-on-hover
                    class="menu-hover"
                >
                  <template v-slot:activator="{ props }">
                    <v-btn
                        class="menu-btn ma-0"
                        v-bind="props"
                    >
                      MRI
                      <v-icon class="mdi mdi-chevron-down"></v-icon>
                    </v-btn>
                  </template>
                  <v-list>
                    <v-list-item>
                      <div class="Dermatologist-details">
                        <a href="javascript:">MRI in Lahore</a>
                        <a href="javascript:">MRI in Islamabad</a>
                        <a href="javascript:">MRI in Rawalpindi</a>
                        <a href="javascript:">MRI in Karachi</a>
                      </div>
                    </v-list-item>
                  </v-list>
                </v-menu>
                <!--///-->
                <v-menu
                    :location="location"
                    open-on-hover
                    class="menu-hover"
                >
                  <template v-slot:activator="{ props }">
                    <v-btn
                        class="menu-btn ma-0"
                        v-bind="props"
                    >
                      CT Scan
                      <v-icon class="mdi mdi-chevron-down"></v-icon>
                    </v-btn>
                  </template>
                  <v-list>
                    <v-list-item>
                      <div class="Dermatologist-details">
                        <a href="javascript:">CT Scan in Lahore</a>
                        <a href="javascript:">CT Scan in Islamabad</a>
                        <a href="javascript:">CT Scan in Rawalpindi</a>
                        <a href="javascript:">CT Scan in Karachi</a>
                      </div>
                    </v-list-item>
                  </v-list>
                </v-menu>
                <!--///-->
                <v-menu
                    :location="location"
                    open-on-hover
                    class="menu-hover"
                >
                  <template v-slot:activator="{ props }">
                    <v-btn
                        class="menu-btn ma-0"
                        v-bind="props"
                    >
                      Laser Hair Removal
                      <v-icon class="mdi mdi-chevron-down"></v-icon>
                    </v-btn>
                  </template>
                  <v-list>
                    <v-list-item>
                      <div class="Dermatologist-details">
                        <a href="javascript:">Laser Hair Removal in Lahore</a>
                        <a href="javascript:">Laser Hair Removal in Islamabad</a>
                        <a href="javascript:">Laser Hair Removal in Rawalpindi</a>
                        <a href="javascript:">Laser Hair Removal in Karachi</a>
                      </div>
                    </v-list-item>
                  </v-list>
                </v-menu>
                <!--///-->
                <v-menu
                    :location="location"
                    open-on-hover
                    class="menu-hover"
                >
                  <template v-slot:activator="{ props }">
                    <v-btn
                        class="menu-btn ma-0"
                        v-bind="props"
                    >
                      Test Tube Baby
                      <v-icon class="mdi mdi-chevron-down"></v-icon>
                    </v-btn>
                  </template>
                  <v-list>
                    <v-list-item>
                      <div class="Dermatologist-details">
                        <a href="javascript:">Test Tube Baby in Lahore</a>
                        <a href="javascript:">Test Tube Baby in Islamabad</a>
                        <a href="javascript:">Test Tube Baby in Rawalpindi</a>
                        <a href="javascript:">Test Tube Baby in Karachi</a>
                      </div>
                    </v-list-item>
                  </v-list>
                </v-menu>
                <!--///-->
                <v-menu
                    :location="location"
                    open-on-hover
                    class="menu-hover"
                >
                  <template v-slot:activator="{ props }">
                    <v-btn
                        class="menu-btn ma-0"
                        v-bind="props"
                    >
                      Penile Implants
                      <v-icon class="mdi mdi-chevron-down"></v-icon>
                    </v-btn>
                  </template>
                  <v-list>
                    <v-list-item>
                      <div class="Dermatologist-details">
                        <a href="javascript:">Penile Implants in Lahore</a>
                        <a href="javascript:">Penile Implants in Islamabad</a>
                        <a href="javascript:">Penile Implants in Rawalpindi</a>
                        <a href="javascript:">Penile Implants in Karachi</a>
                      </div>
                    </v-list-item>
                  </v-list>
                </v-menu>
                <!--///-->
                <v-menu
                    :location="location"
                    open-on-hover
                    class="menu-hover"
                >
                  <template v-slot:activator="{ props }">
                    <v-btn
                        class="menu-btn ma-0"
                        v-bind="props"
                    >
                      Normal Delivery
                      <v-icon class="mdi mdi-chevron-down"></v-icon>
                    </v-btn>
                  </template>
                  <v-list>
                    <v-list-item>
                      <div class="Dermatologist-details">
                        <a href="javascript:">Normal Delivery in Lahore</a>
                        <a href="javascript:">Normal Delivery in Islamabad</a>
                        <a href="javascript:">Normal Delivery in Rawalpindi</a>
                        <a href="javascript:">Normal Delivery in Karachi</a>
                      </div>
                    </v-list-item>
                  </v-list>
                </v-menu>
                <!--///-->
                <v-menu
                    :location="location"
                    open-on-hover
                    class="menu-hover"
                >
                  <template v-slot:activator="{ props }">
                    <v-btn
                        class="menu-btn ma-0"
                        v-bind="props"
                    >
                      IVF
                      <v-icon class="mdi mdi-chevron-down"></v-icon>
                    </v-btn>
                  </template>
                  <v-list>
                    <v-list-item>
                      <div class="Dermatologist-details">
                        <a href="javascript:">IVF in Lahore</a>
                        <a href="javascript:">IVF in Islamabad</a>
                        <a href="javascript:">IVF in Rawalpindi</a>
                        <a href="javascript:">IVF in Karachi</a>
                      </div>
                    </v-list-item>
                  </v-list>
                </v-menu>
                <!--///-->
                <v-menu
                    :location="location"
                    open-on-hover
                    class="menu-hover"
                >
                  <template v-slot:activator="{ props }">
                    <v-btn
                        class="menu-btn ma-0"
                        v-bind="props"
                    >
                      LASER For Skin
                      <v-icon class="mdi mdi-chevron-down"></v-icon>
                    </v-btn>
                  </template>
                  <v-list>
                    <v-list-item>
                      <div class="Dermatologist-details">
                        <a href="javascript:">LASER For Skin in Lahore</a>
                        <a href="javascript:">LASER For Skin in Islamabad</a>
                        <a href="javascript:">LASER For Skin in Rawalpindi</a>
                        <a href="javascript:">LASER For Skin in Karachi</a>
                      </div>
                    </v-list-item>
                  </v-list>
                </v-menu>
                <!--///-->
                <v-menu
                    :location="location"
                    open-on-hover
                    class="menu-hover"
                >
                  <template v-slot:activator="{ props }">
                    <v-btn
                        class="menu-btn ma-0"
                        v-bind="props"
                    >
                      Vaccination
                      <v-icon class="mdi mdi-chevron-down"></v-icon>
                    </v-btn>
                  </template>
                  <v-list>
                    <v-list-item>
                      <div class="Dermatologist-details">
                        <a href="javascript:">Vaccination in Lahore</a>
                        <a href="javascript:">Vaccination in Islamabad</a>
                        <a href="javascript:">Vaccination in Rawalpindi</a>
                        <a href="javascript:">Vaccination in Karachi</a>
                      </div>
                    </v-list-item>
                  </v-list>
                </v-menu>
                <!--///-->
                <v-menu
                    :location="location"
                    open-on-hover
                    class="menu-hover"
                >
                  <template v-slot:activator="{ props }">
                    <v-btn
                        class="menu-btn ma-0"
                        v-bind="props"
                    >
                      All Treatments
                      <v-icon class="mdi mdi-chevron-down"></v-icon>
                    </v-btn>
                  </template>
                  <v-list>
                    <v-list-item>
                      <div class="Dermatologist-details">
                        <a href="javascript:">All Treatments in Lahore</a>
                        <a href="javascript:">All Treatments in Islamabad</a>
                        <a href="javascript:">All Treatments in Rawalpindi</a>
                        <a href="javascript:">All Treatments in Karachi</a>
                      </div>
                    </v-list-item>
                  </v-list>
                </v-menu>
                <!--///-->
              </div>
              <!--/// Find Doctor By Treatment End Here ///-->

              <!--/// Find Doctor By Condition Start Here ///-->
              <div class="details-box">
                <h3>Find doctor by Condition</h3>
                <v-menu
                    :location="location"
                    open-on-hover
                    class="menu-hover"
                >
                  <template v-slot:activator="{ props }">
                    <v-btn
                        class="menu-btn ma-0"
                        v-bind="props"
                    >
                      Piles
                      <v-icon class="mdi mdi-chevron-down"></v-icon>
                    </v-btn>
                  </template>
                  <v-list>
                    <v-list-item>
                      <div class="Dermatologist-details">
                        <a href="javascript:">Piles in Lahore</a>
                        <a href="javascript:">Piles in Islamabad</a>
                        <a href="javascript:">Piles in Rawalpindi</a>
                        <a href="javascript:">Piles in Karachi</a>
                      </div>
                    </v-list-item>
                  </v-list>
                </v-menu>
                <!--///-->
                <v-menu
                    :location="location"
                    open-on-hover
                    class="menu-hover"
                >
                  <template v-slot:activator="{ props }">
                    <v-btn
                        class="menu-btn ma-0"
                        v-bind="props"
                    >
                      Male Sexual Dysfunction
                      <v-icon class="mdi mdi-chevron-down"></v-icon>
                    </v-btn>
                  </template>
                  <v-list>
                    <v-list-item>
                      <div class="Dermatologist-details">
                        <a href="javascript:">Male Sexual Dysfunction in Lahore</a>
                        <a href="javascript:">Male Sexual Dysfunction in Islamabad</a>
                        <a href="javascript:">Male Sexual Dysfunction in Rawalpindi</a>
                        <a href="javascript:">Male Sexual Dysfunction in Karachi</a>
                      </div>
                    </v-list-item>
                  </v-list>
                </v-menu>
                <!--///-->
                <v-menu
                    :location="location"
                    open-on-hover
                    class="menu-hover"
                >
                  <template v-slot:activator="{ props }">
                    <v-btn
                        class="menu-btn ma-0"
                        v-bind="props"
                    >
                      Male Infertility
                      <v-icon class="mdi mdi-chevron-down"></v-icon>
                    </v-btn>
                  </template>
                  <v-list>
                    <v-list-item>
                      <div class="Dermatologist-details">
                        <a href="javascript:">Male Infertility in Lahore</a>
                        <a href="javascript:">Male Infertility in Islamabad</a>
                        <a href="javascript:">Male Infertility in Rawalpindi</a>
                        <a href="javascript:">Male Infertility in Karachi</a>
                      </div>
                    </v-list-item>
                  </v-list>
                </v-menu>
                <!--///-->
                <v-menu
                    :location="location"
                    open-on-hover
                    class="menu-hover"
                >
                  <template v-slot:activator="{ props }">
                    <v-btn
                        class="menu-btn ma-0"
                        v-bind="props"
                    >
                      Hernia
                      <v-icon class="mdi mdi-chevron-down"></v-icon>
                    </v-btn>
                  </template>
                  <v-list>
                    <v-list-item>
                      <div class="Dermatologist-details">
                        <a href="javascript:">Hernia in Lahore</a>
                        <a href="javascript:">Hernia in Islamabad</a>
                        <a href="javascript:">Hernia in Rawalpindi</a>
                        <a href="javascript:">Hernia in Karachi</a>
                      </div>
                    </v-list-item>
                  </v-list>
                </v-menu>
                <!--///-->
                <v-menu
                    :location="location"
                    open-on-hover
                    class="menu-hover"
                >
                  <template v-slot:activator="{ props }">
                    <v-btn
                        class="menu-btn ma-0"
                        v-bind="props"
                    >
                      Erectile Dysfunction
                      <v-icon class="mdi mdi-chevron-down"></v-icon>
                    </v-btn>
                  </template>
                  <v-list>
                    <v-list-item>
                      <div class="Dermatologist-details">
                        <a href="javascript:">Erectile Dysfunction in Lahore</a>
                        <a href="javascript:">Erectile Dysfunction in Islamabad</a>
                        <a href="javascript:">Erectile Dysfunction in Rawalpindi</a>
                        <a href="javascript:">Erectile Dysfunction in Karachi</a>
                      </div>
                    </v-list-item>
                  </v-list>
                </v-menu>
                <!--///-->
                <v-menu
                    :location="location"
                    open-on-hover
                    class="menu-hover"
                >
                  <template v-slot:activator="{ props }">
                    <v-btn
                        class="menu-btn ma-0"
                        v-bind="props"
                    >
                      Acne Scars
                      <v-icon class="mdi mdi-chevron-down"></v-icon>
                    </v-btn>
                  </template>
                  <v-list>
                    <v-list-item>
                      <div class="Dermatologist-details">
                        <a href="javascript:">Acne Scars in Lahore</a>
                        <a href="javascript:">Acne Scars in Islamabad</a>
                        <a href="javascript:">Acne Scars in Rawalpindi</a>
                        <a href="javascript:">Acne Scars in Karachi</a>
                      </div>
                    </v-list-item>
                  </v-list>
                </v-menu>
                <!--///-->
                <v-menu
                    :location="location"
                    open-on-hover
                    class="menu-hover"
                >
                  <template v-slot:activator="{ props }">
                    <v-btn
                        class="menu-btn ma-0"
                        v-bind="props"
                    >
                      Skin Diseases
                      <v-icon class="mdi mdi-chevron-down"></v-icon>
                    </v-btn>
                  </template>
                  <v-list>
                    <v-list-item>
                      <div class="Dermatologist-details">
                        <a href="javascript:">Skin Diseases in Lahore</a>
                        <a href="javascript:">Skin Diseases in Islamabad</a>
                        <a href="javascript:">Skin Diseases in Rawalpindi</a>
                        <a href="javascript:">Skin Diseases in Karachi</a>
                      </div>
                    </v-list-item>
                  </v-list>
                </v-menu>
                <!--///-->
                <v-menu
                    :location="location"
                    open-on-hover
                    class="menu-hover"
                >
                  <template v-slot:activator="{ props }">
                    <v-btn
                        class="menu-btn ma-0"
                        v-bind="props"
                    >
                      Thyroid Disease
                      <v-icon class="mdi mdi-chevron-down"></v-icon>
                    </v-btn>
                  </template>
                  <v-list>
                    <v-list-item>
                      <div class="Dermatologist-details">
                        <a href="javascript:">Thyroid Disease in Lahore</a>
                        <a href="javascript:">Thyroid Disease in Islamabad</a>
                        <a href="javascript:">Thyroid Disease in Rawalpindi</a>
                        <a href="javascript:">Thyroid Disease in Karachi</a>
                      </div>
                    </v-list-item>
                  </v-list>
                </v-menu>
                <!--///-->
                <v-menu
                    :location="location"
                    open-on-hover
                    class="menu-hover"
                >
                  <template v-slot:activator="{ props }">
                    <v-btn
                        class="menu-btn ma-0"
                        v-bind="props"
                    >
                      Testicular Torsion
                      <v-icon class="mdi mdi-chevron-down"></v-icon>
                    </v-btn>
                  </template>
                  <v-list>
                    <v-list-item>
                      <div class="Dermatologist-details">
                        <a href="javascript:">Testicular Torsion in Lahore</a>
                        <a href="javascript:">Testicular Torsion in Islamabad</a>
                        <a href="javascript:">Testicular Torsion in Rawalpindi</a>
                        <a href="javascript:">Testicular Torsion in Karachi</a>
                      </div>
                    </v-list-item>
                  </v-list>
                </v-menu>
                <!--///-->
                <v-menu
                    :location="location"
                    open-on-hover
                    class="menu-hover"
                >
                  <template v-slot:activator="{ props }">
                    <v-btn
                        class="menu-btn ma-0"
                        v-bind="props"
                    >
                      All Conditions
                      <v-icon class="mdi mdi-chevron-down"></v-icon>
                    </v-btn>
                  </template>
                  <v-list>
                    <v-list-item>
                      <div class="Dermatologist-details">
                        <a href="javascript:">All Conditions in Lahore</a>
                        <a href="javascript:">All Conditions in Islamabad</a>
                        <a href="javascript:">All Conditions in Rawalpindi</a>
                        <a href="javascript:">All Conditions in Karachi</a>
                      </div>
                    </v-list-item>
                  </v-list>
                </v-menu>
                <!--///-->
              </div>
              <!--/// Find Doctor By Condition End Here ///-->

              <!--/// Find Doctor online Start Here ///-->
              <div class="details-box">
                <h3>Find doctors online</h3>
                <v-btn class="menu-btn ma-0 ptb-8">Online Dermatologist</v-btn>
                <v-btn class="menu-btn ma-0 ptb-8">Online Gynecologist</v-btn>
                <v-btn class="menu-btn ma-0 ptb-8">Online Psychiatrist</v-btn>
                <v-btn class="menu-btn ma-0 ptb-8">Online Urologist</v-btn>
                <v-btn class="menu-btn ma-0 ptb-8">Online Sexologist</v-btn>
                <v-btn class="menu-btn ma-0 ptb-8">Online ENT Specialist</v-btn>
                <v-btn class="menu-btn ma-0 ptb-8">Online Eye Specialist</v-btn>
                <v-btn class="menu-btn ma-0 ptb-8">Online Neurologist</v-btn>
                <v-btn class="menu-btn ma-0 ptb-8">Online Orthopedic Surgeon</v-btn>
                <v-btn class="menu-btn ma-0 ptb-8">Online Child Specialist</v-btn>
              </div>
              <!--/// Find Doctor online End Here ///-->

              <!--/// Find Doctor Surgeon Start Here ///-->
              <div class="details-box">
                <h3>Find Surgeon</h3>
                <v-menu
                    :location="location"
                    open-on-hover
                    class="menu-hover"
                >
                  <template v-slot:activator="{ props }">
                    <v-btn
                        class="menu-btn ma-0"
                        v-bind="props"
                    >
                      Scaling And Polishing
                      <v-icon class="mdi mdi-chevron-down"></v-icon>
                    </v-btn>
                  </template>
                  <v-list>
                    <v-list-item>
                      <div class="Dermatologist-details">
                        <a href="javascript:">Scaling And Polishing in Lahore</a>
                        <a href="javascript:">Scaling And Polishing in Islamabad</a>
                        <a href="javascript:">Scaling And Polishing in Rawalpindi</a>
                        <a href="javascript:">Scaling And Polishing in Karachi</a>
                      </div>
                    </v-list-item>
                  </v-list>
                </v-menu>
                <!--///-->
                <v-menu
                    :location="location"
                    open-on-hover
                    class="menu-hover"
                >
                  <template v-slot:activator="{ props }">
                    <v-btn
                        class="menu-btn ma-0"
                        v-bind="props"
                    >
                      Dental Implants
                      <v-icon class="mdi mdi-chevron-down"></v-icon>
                    </v-btn>
                  </template>
                  <v-list>
                    <v-list-item>
                      <div class="Dermatologist-details">
                        <a href="javascript:">Dental Implants in Lahore</a>
                        <a href="javascript:">Dental Implants in Islamabad</a>
                        <a href="javascript:">Dental Implants in Rawalpindi</a>
                        <a href="javascript:">Dental Implants in Karachi</a>
                      </div>
                    </v-list-item>
                  </v-list>
                </v-menu>
                <!--///-->
                <v-menu
                    :location="location"
                    open-on-hover
                    class="menu-hover"
                >
                  <template v-slot:activator="{ props }">
                    <v-btn
                        class="menu-btn ma-0"
                        v-bind="props"
                    >
                      LASIK Eye Surgery
                      <v-icon class="mdi mdi-chevron-down"></v-icon>
                    </v-btn>
                  </template>
                  <v-list>
                    <v-list-item>
                      <div class="Dermatologist-details">
                        <a href="javascript:">LASIK Eye Surgery in Lahore</a>
                        <a href="javascript:">LASIK Eye Surgery in Islamabad</a>
                        <a href="javascript:">LASIK Eye Surgery in Rawalpindi</a>
                        <a href="javascript:">LASIK Eye Surgery in Karachi</a>
                      </div>
                    </v-list-item>
                  </v-list>
                </v-menu>
                <!--///-->
                <v-menu
                    :location="location"
                    open-on-hover
                    class="menu-hover"
                >
                  <template v-slot:activator="{ props }">
                    <v-btn
                        class="menu-btn ma-0"
                        v-bind="props"
                    >
                      Rhinoplasty
                      <v-icon class="mdi mdi-chevron-down"></v-icon>
                    </v-btn>
                  </template>
                  <v-list>
                    <v-list-item>
                      <div class="Dermatologist-details">
                        <a href="javascript:">Rhinoplasty in Lahore</a>
                        <a href="javascript:">Rhinoplasty in Islamabad</a>
                        <a href="javascript:">Rhinoplasty in Rawalpindi</a>
                        <a href="javascript:">Rhinoplasty in Karachi</a>
                      </div>
                    </v-list-item>
                  </v-list>
                </v-menu>
                <!--///-->
                <v-menu
                    :location="location"
                    open-on-hover
                    class="menu-hover"
                >
                  <template v-slot:activator="{ props }">
                    <v-btn
                        class="menu-btn ma-0"
                        v-bind="props"
                    >
                      Root Canal
                      <v-icon class="mdi mdi-chevron-down"></v-icon>
                    </v-btn>
                  </template>
                  <v-list>
                    <v-list-item>
                      <div class="Dermatologist-details">
                        <a href="javascript:">Root Canal in Lahore</a>
                        <a href="javascript:">Root Canal in Islamabad</a>
                        <a href="javascript:">Root Canal in Rawalpindi</a>
                        <a href="javascript:">Root Canal in Karachi</a>
                      </div>
                    </v-list-item>
                  </v-list>
                </v-menu>
                <!--///-->
              </div>
              <!--/// Find Doctor Surgeon Start Here ///-->
            </div>
          </v-list-item>
        </v-container>
      </v-list>
    </v-menu>
    <!--/// Doctors Menu End Here ///-->

    <!--/// Hospitals Menu Start Here ///-->
    <v-menu class="custom-menu">
      <template v-slot:activator="{ props }">
        <v-btn
            class="menu-btn"
            v-bind="props"
        >
          Hospitals
          <v-icon class="mdi mdi-chevron-down"></v-icon>
        </v-btn>
      </template>
      <v-list class="pb-0">
        <v-container>
          <v-list-item>
            <h4>Choose a hospital</h4>
            <a href="javascript:" class="d-block mb-7">
              View all hospital
              <v-icon class="mdi mdi-chevron-right"></v-icon>
            </a>
            <div class="scroll-wrapper">
              <!--/// Lahore Hospital Start Here ///-->
              <div class="details-box w-25">
                <h3>Lahore</h3>
                <v-btn class="menu-btn ma-0 plr-0">Doctors Hospital</v-btn>
                <v-btn class="menu-btn ma-0 plr-0">Hameed Latif Hospital</v-btn>
                <v-btn class="menu-btn ma-0 plr-0">Omar Hospital & Cardiac Centre</v-btn>
                <v-btn class="menu-btn ma-0 plr-0">Mid City Hospital</v-btn>
                <v-btn class="menu-btn ma-0 plr-0">Surgimed Hospital</v-btn>
                <v-btn class="menu-btn ma-0 plr-0">National Hospital & Medical Center</v-btn>
                <v-btn class="menu-btn ma-0 plr-0">Evercare Hospital</v-btn>
                <v-btn class="menu-btn ma-0 plr-0">Fatima Memorial Hospital</v-btn>
                <v-btn class="menu-btn ma-0 plr-0">Ittefaq Hospital</v-btn>
                <v-btn class="menu-btn ma-0 plr-0">Iqra Medical Complex</v-btn>
                <v-btn class="menu-btn ma-0 plr-0">All Hospitals in Lahore</v-btn>
              </div>
              <!--/// Lahore Hospital End Here ///-->

              <!--/// Karachi Hospital Start Here ///-->
              <div class="details-box w-25">
                <h3>Karachi</h3>
                <v-btn class="menu-btn ma-0 plr-0">Hashmanis Hospital</v-btn>
                <v-btn class="menu-btn ma-0 plr-0">South City Hospital</v-btn>
                <v-btn class="menu-btn ma-0 plr-0">Altamash General Hospital</v-btn>
                <v-btn class="menu-btn ma-0 plr-0">Medicare Cardiac & General Hospital</v-btn>
                <v-btn class="menu-btn ma-0 plr-0">Dr. Ziauddin Hospital (North Nazimabad)</v-btn>
                <v-btn class="menu-btn ma-0 plr-0">National Medical Centre (NMC)</v-btn>
                <v-btn class="menu-btn ma-0 plr-0">Darul Sehat Hospital</v-btn>
                <v-btn class="menu-btn ma-0 plr-0">Patel Hospital</v-btn>
                <v-btn class="menu-btn ma-0 plr-0">Saifee Hospital</v-btn>
                <v-btn class="menu-btn ma-0 plr-0">Aga Khan University Hospital</v-btn>
                <v-btn class="menu-btn ma-0 plr-0">All Hospitals in Karachi</v-btn>
              </div>
              <!--/// Karachi Hospital End Here ///-->

              <!--/// Islamabad Hospital Start Here ///-->
              <div class="details-box w-25">
                <h3>Islamabad</h3>
                <v-btn class="menu-btn ma-0 plr-0">Advanced International Hospital</v-btn>
                <v-btn class="menu-btn ma-0 plr-0">Islamabad International Hospital</v-btn>
                <v-btn class="menu-btn ma-0 plr-0">Shifa International Hospital</v-btn>
                <v-btn class="menu-btn ma-0 plr-0">Quaid-e-Azam International Hospital</v-btn>
                <v-btn class="menu-btn ma-0 plr-0">Maroof International Hospital</v-btn>
                <v-btn class="menu-btn ma-0 plr-0">Ali Medical Centre</v-btn>
                <v-btn class="menu-btn ma-0 plr-0">Kulsum International Hospital</v-btn>
                <v-btn class="menu-btn ma-0 plr-0">Islamabad Specialists Clinic</v-btn>
                <v-btn class="menu-btn ma-0 plr-0">MaxHealth Hospital</v-btn>
                <v-btn class="menu-btn ma-0 plr-0">Chinar International Hospital</v-btn>
                <v-btn class="menu-btn ma-0 plr-0">All Hospitals in Islamabad</v-btn>
              </div>
              <!--/// Islamabad Hospital End Here ///-->

              <!--/// Rawalpindi Hospital Start Here ///-->
              <div class="details-box w-25">
                <h3>Rawalpindi</h3>
                <v-btn class="menu-btn ma-0 plr-0">Reliance Hospital</v-btn>
                <v-btn class="menu-btn ma-0 plr-0">Khatoon Hospital</v-btn>
                <v-btn class="menu-btn ma-0 plr-0">Shaafi International Hospital</v-btn>
                <v-btn class="menu-btn ma-0 plr-0">Fauji Foundation Hospital</v-btn>
                <v-btn class="menu-btn ma-0 plr-0">Shamshad Aslam Uppal Hospital</v-btn>
                <v-btn class="menu-btn ma-0 plr-0">Hearts International Hospital</v-btn>
                <v-btn class="menu-btn ma-0 plr-0">Maryam Memorial Hospital</v-btn>
                <v-btn class="menu-btn ma-0 plr-0">Hanif Hospital (Rawalpindi)</v-btn>
                <v-btn class="menu-btn ma-0 plr-0">All Hospitals in Rawalpindi</v-btn>
              </div>
              <!--/// Rawalpindi Hospital End Here ///-->
            </div>
          </v-list-item>
        </v-container>
      </v-list>
    </v-menu>
    <!--/// Hospitals Menu End Here ///-->

    <!--Labs & Diagnostics Menu Start Here-->
    <v-menu class="custom-menu">
      <template v-slot:activator="{ props }">
        <v-btn
            class="menu-btn"
            v-bind="props"
        >
          Labs & Diagnostics
          <v-icon class="mdi mdi-chevron-down"></v-icon>
        </v-btn>
      </template>
      <v-list class="pb-0">
        <v-container>
          <v-list-item>
            <h4>Choose a lab</h4>
            <a href="javascript:" class="d-block mb-7">
              View all labs
              <v-icon class="mdi mdi-chevron-right"></v-icon>
            </a>
            <div class="scroll-wrapper flex-column pr-2">
              <!--/// Lahore Labs Start Here ///-->
              <div class="labs-detail">
                <h3>Lahore</h3>
                <v-row gutters="8">
                  <v-col cols="12" md="3">
                    <v-btn class="">
                      <img src="../images/lab-one.jpg" alt="Lab One">
                      <span class="btn-text">Chughtai Lab</span>
                    </v-btn>
                  </v-col>
                  <v-col cols="12" md="3">
                    <v-btn class="">
                      <img src="../images/lab-two.png" alt="Lab Two">
                      <span class="btn-text">Al Razi Healthcare</span>
                    </v-btn>
                  </v-col>
                  <v-col cols="12" md="3">
                    <v-btn class="">
                      <img src="../images/lab-three.jpg" alt="Lab Three">
                      <span class="btn-text">Advanced Diagnostic</span>
                    </v-btn>
                  </v-col>
                  <v-col cols="12" md="3">
                    <v-btn class="">
                      <img src="../images/lab-four.jpg" alt="Lab Four">
                      <span class="btn-text">Alnoor Diagnostic</span>
                    </v-btn>
                  </v-col>
                  <v-col cols="12" md="3">
                    <v-btn class="">
                      <img src="../images/lab-five.jpg" alt="Lab Five">
                      <span class="btn-text">Dr. Essa Lab</span>
                    </v-btn>
                  </v-col>
                  <v-col cols="12" md="3">
                    <v-btn class="">
                      <img src="../images/lab-six.jpg" alt="Lab Six">
                      <span class="btn-text">One Health Lab</span>
                    </v-btn>
                  </v-col>
                </v-row>
              </div>
              <!--/// Lahore Labs End Here ///-->

              <!--/// Islamabad Labs Start Here ///-->
              <div class="labs-detail">
                <h3>Islamabad</h3>
                <v-row gutters="8">
                  <v-col cols="12" md="3">
                    <v-btn class="">
                      <img src="../images/lab-one.jpg" alt="Lab One">
                      <span class="btn-text">Chughtai Lab</span>
                    </v-btn>
                  </v-col>
                  <v-col cols="12" md="3">
                    <v-btn class="">
                      <img src="../images/lab-two.png" alt="Lab Two">
                      <span class="btn-text">Al Razi Healthcare</span>
                    </v-btn>
                  </v-col>
                  <v-col cols="12" md="3">
                    <v-btn class="">
                      <img src="../images/lab-three.jpg" alt="Lab Three">
                      <span class="btn-text">Advanced Diagnostic</span>
                    </v-btn>
                  </v-col>
                  <v-col cols="12" md="3">
                    <v-btn class="">
                      <img src="../images/lab-four.jpg" alt="Lab Four">
                      <span class="btn-text">Alnoor Diagnostic</span>
                    </v-btn>
                  </v-col>
                  <v-col cols="12" md="3">
                    <v-btn class="">
                      <img src="../images/lab-five.jpg" alt="Lab Five">
                      <span class="btn-text">Dr. Essa Lab</span>
                    </v-btn>
                  </v-col>
                  <v-col cols="12" md="3">
                    <v-btn class="">
                      <img src="../images/lab-six.jpg" alt="Lab Six">
                      <span class="btn-text">One Health Lab</span>
                    </v-btn>
                  </v-col>
                </v-row>
              </div>
              <!--/// Islamabad Labs End Here ///-->

              <!--/// Karachi Labs Start Here ///-->
              <div class="labs-detail">
                <h3>Karachi</h3>
                <v-row gutters="8">
                  <v-col cols="12" md="3">
                    <v-btn class="">
                      <img src="../images/lab-one.jpg" alt="Lab One">
                      <span class="btn-text">Chughtai Lab</span>
                    </v-btn>
                  </v-col>
                  <v-col cols="12" md="3">
                    <v-btn class="">
                      <img src="../images/lab-two.png" alt="Lab Two">
                      <span class="btn-text">Al Razi Healthcare</span>
                    </v-btn>
                  </v-col>
                  <v-col cols="12" md="3">
                    <v-btn class="">
                      <img src="../images/lab-three.jpg" alt="Lab Three">
                      <span class="btn-text">Advanced Diagnostic</span>
                    </v-btn>
                  </v-col>
                  <v-col cols="12" md="3">
                    <v-btn class="">
                      <img src="../images/lab-four.jpg" alt="Lab Four">
                      <span class="btn-text">Alnoor Diagnostic</span>
                    </v-btn>
                  </v-col>
                  <v-col cols="12" md="3">
                    <v-btn class="">
                      <img src="../images/lab-five.jpg" alt="Lab Five">
                      <span class="btn-text">Dr. Essa Lab</span>
                    </v-btn>
                  </v-col>
                  <v-col cols="12" md="3">
                    <v-btn class="">
                      <img src="../images/lab-six.jpg" alt="Lab Six">
                      <span class="btn-text">One Health Lab</span>
                    </v-btn>
                  </v-col>
                </v-row>
              </div>
              <!--/// Karachi Labs End Here ///-->
            </div>
          </v-list-item>
        </v-container>
      </v-list>
    </v-menu>
    <!--Labs & Diagnostics Menu End Here-->
    <a href="javascript:" class="health-blog">Health Blog</a>
    <div class="ml-auto">
      <v-btn variant="outlined" class="mr-4 text-inherit">
        LogIn/SignUp
      </v-btn>
      <v-btn class="text-inherit" variant="outlined" color="secondary">
        Join as Doctor
      </v-btn>
    </div>
  </v-app-bar>
</template>

<script>

export default {
  data: () => ({
    locations: [
      'top',
      'bottom',
      'start',
      'end',
      'center',
    ],
    location: 'end',
  }),
}

</script>

<style scoped>

.top-nav:deep(.v-toolbar__content) {
  padding: 8px 24px;
}
.logo {
  width: 180px;
  margin-right: 16px;
}
.menu-btn {
  padding: 0 5px 0 5px;
  margin: 0 10px 0 10px;
  height: auto !important;
}
.menu-btn:deep(.v-btn__overlay) {
  display: none;
}
.menu-btn:deep(.v-btn__underlay) {
  display: none;
}
.menu-btn:after {
  display: none;
}
.menu-btn:deep(.v-ripple__container) {
  display: none;
}
.menu-btn:deep(.v-btn__content) {
  color: #46484b;
  font-size: 14px;
  font-weight: 500;
  text-transform: capitalize;
}
.menu-btn .v-icon {
  color: #000000;
  font-size: 22px;
  margin: 2px 0 0 5px;
  transition: all 0.3s ease;
}
.menu-btn[aria-expanded="true"] .v-icon {
  transform: rotate(180deg);
  transition: all 0.3s ease;
}
.health-blog {
  color: #46484b;
  font-size: 14px;
  font-weight: 500;
  text-decoration: none;
  text-transform: capitalize;
}
.text-inherit {
  text-transform: inherit;
}
.custom-menu:deep(.v-overlay__content) {
  border-radius: 0;
  left: 0 !important;
  top: 64px !important;
  min-width: 100% !important;
}
.custom-menu:deep(.v-list) {
  border-top: 1px solid #f5f5f5;
  box-shadow: 0 .185rem .25rem rgba(0,0,0,.075)!important;
}
.custom-menu:deep(.v-list .v-list-item) {
  min-height: auto;
  padding: 0 !important;
}
.custom-menu h4 {
  color: #212529;
  font-size: 30px;
  font-weight: 400;
  line-height: 30px;
  margin-bottom: 10px;
}
.custom-menu a {
  color: #212529;
  font-size: 14px;
  font-weight: 500;
  text-decoration: none;
}
.custom-menu a:hover {
  color: #2f74bf;
}
.custom-menu a .v-icon {
  font-size: 16px;
}
.scroll-wrapper {
  display: flex;
  overflow: auto;
  max-height: 370px;
  overflow-x: hidden;
}
/* width */
.scroll-wrapper::-webkit-scrollbar {
  width: 6px;
}
/* Track */
.scroll-wrapper::-webkit-scrollbar-track {
  background: #f1f1f1;
}
/* Handle */
.scroll-wrapper::-webkit-scrollbar-thumb {
  background: #2f74bf;
  border-radius: 20px;
}
/* Handle on hover */
.scroll-wrapper::-webkit-scrollbar-thumb:hover {
  background: #555;
  border-radius: 20px;
}
.scroll-wrapper .details-box {
  width: 20%;
}
.scroll-wrapper .labs-detail {
  width: 100%;
  margin-bottom: 25px;
}
.scroll-wrapper .labs-detail:last-of-type {
  margin-bottom: 0;
}
.scroll-wrapper .labs-detail .v-btn {
  width: 100%;
  height: auto;
  display: flex;
  color: #212529;
  font-size: 14px;
  font-weight: 400;
  border-radius: 5px;
  align-items: center;
  letter-spacing: 0.3px;
  text-transform: inherit;
  justify-content: flex-start;
  box-shadow: 0 0 0 0 #cecece;
  padding: 10px 15px 10px 15px;
  border: 1px solid rgb(223, 225, 228);
}
.scroll-wrapper .labs-detail .v-btn:deep(.v-btn__content) {
  width: 100%;
}
.scroll-wrapper .labs-detail .v-btn img {
  width: 40px;
  height: 40px;
  margin-right: 15px;
  border-radius: 50%;
}
.scroll-wrapper .labs-detail .v-btn .btn-text {
  width: 100%;
  margin-top: 2px;
  text-align: left;
  overflow: hidden;
  white-space: nowrap;
  display: inline-block;
  text-overflow: ellipsis;
}
.scroll-wrapper .labs-detail h3 {
  margin-bottom: 10px;
}
.scroll-wrapper .details-box h3 {
  color: #212529;
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  margin-bottom: 20px;
}
.scroll-wrapper .details-box .menu-btn {
  width: 90%;
  border-radius: 0;
  overflow: hidden;
  white-space: nowrap;
  display: inline-block;
  text-overflow: ellipsis;
  padding: 6px 4px !important;
  box-shadow: 0 0 0 0 #cecece;
}
.scroll-wrapper .details-box .menu-btn.plr-0 {
  width: 95%;
  padding-left: 0 !important;
  padding-right: 0 !important;
}
.scroll-wrapper .details-box .menu-btn.ptb-8 {
  padding-top: 8px !important;
  padding-bottom: 8px !important;
}
.scroll-wrapper .details-box .menu-btn:deep(.v-btn__content) {
  color: #212529;
  font-size: 14px;
  font-weight: 400;
  justify-content: flex-start;
}
.scroll-wrapper .details-box .menu-btn .v-icon {
  font-size: 20px;
  margin: 1px 0 0 2px;
}
.scroll-wrapper .details-box .menu-btn:hover {
  background: #f2f8ff;
}
.menu-hover:deep(.v-list) {
  background: #2f74bf !important;
}
.menu-hover:deep(.v-list .Dermatologist-details) {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.menu-hover:deep(.v-list .Dermatologist-details a) {
  width: 100%;
  display: block;
  color: #ffffff;
  font-size: 14px;
  font-weight: 400;
  line-height: 26px;
  text-decoration: none;
}
.menu-hover:deep(.v-list .Dermatologist-details a:hover) {
  text-decoration: underline;
}

</style>