<template>
  <div
      class="loading-container"
      :class="{ block }"
      :style="containerStyle"
      v-if="isVisible"
  >
    <v-progress-circular
        v-bind:size="size"
        v-bind:width="width"
        v-bind:color="color"
        indeterminate
    ></v-progress-circular>
    <p v-if="text">{{ text }}</p>
  </div>
</template>

<script>
import { colors } from "@/theme";

export default {
  name: "Loading",
  props: {
    isVisible: Boolean,
    size: {
      type: Number,
      default: 70
    },
    width: {
      type: Number,
      default: 3
    },
    color: {
      type: String,
      default: colors.primary
    },
    containerStyle: {
      type: Object
    },
    block: Boolean,
    text: String
  }
};
</script>

<style scoped>
.loading-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  flex-direction: column;
}
.block {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 9;
  background: rgba(0, 0, 0, 0.3);
}
p {
  margin: 15px 0 0 0;
}
</style>