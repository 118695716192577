<template>
  <div class="content-wrapper">
    <div class="">
      <!-- <TopBarWhiteBackgroundPatients /> -->
      <div class="align-box calc-height">
        <Loading
            v-if="loading"
            :isVisible="loading"
            text="Patient Prescription"
            class="calc-height-print"
        />
        <v-container class="pt-0 pb-0" v-if="visible">
          <div class="print-content">
            <div class="detail-container">
              <div class="doc-details">
                <h4>{{ drprofile?.title }}</h4>
                <h1>{{ drprofile?.name }}</h1>
                <div class="text-right line-height">
                  <span>{{ drprofile.qualification }}</span>
                </div>
                <h4
                  class="mb-1"
                  v-for="cred of computedCredentials"
                  :key="cred"
                >
                  {{ cred }}
                </h4>
              </div>
              <div class="patient-details">
                <div class="text-right">
                  <img
                    v-if="drprofile.logo"
                    class="logo"
                    :src="drprofile.logo"
                    alt=""
                  />
                </div>
                <div class="user-detail">
                  Date :
                  <span>{{ formatVisitDate(visitSummary?.visitdate) }}</span>
                </div>
                <div class="user-detail">
                  Patient Name :
                  <span>{{ patient?.name }}</span>
                </div>
                <div class="user-detail">
                  Patient Age :
                  <span>{{ ageFromDate(patient?.dateofbirth) }}</span>
                </div>
                <div class="user-detail">
                  Patient Number :
                  <span>{{ patient?.key }}</span>
                </div>
              </div>
            </div>
            <!--/// Doctor & Patient Details End Here ///-->
            <div class="treatment-details">
              <div class="complaints-investigation">
                <div class="investigation-card">
                  <h2>Presenting Complaints</h2>
                  <div class="text-body-2">
                    {{ visit_history }}
                  </div>
                </div>
                <div class="investigation-card">
                  <h2>Past Medical and Surgical History</h2>
                  <div class="text-body-2">
                    {{ visit_pasthistory }}
                  </div>
                </div>
                <div class="investigation-card">
                  <h2>Examination</h2>
                  <div class="text-body-2">
                    {{ visit_examination }}
                  </div>
                </div>
                <div class="investigation-card">
                  <h2>Investigations</h2>
                  <div class="text-body-2">
                    {{ visit_investigations }}
                  </div>
                </div>
                <div class="investigation-card">
                  <h2>Further investigations</h2>
                  <div class="text-body-2">
                    <ul class="ml-4">
                      <li
                        v-for="(
                          item, index
                        ) in visit_furtherinvestigationsarray"
                        :key="index"
                      >
                        {{ item }}
                      </li>
                    </ul>
                  </div>
                </div>
                <div class="investigation-card">
                  <h2>Follow up</h2>
                  <div class="text-body-2">
                    {{ visit_nextvisit?.number }} {{ visit_nextvisit?.dwmy }}
                  </div>
                </div>
              </div>
              <!--///-->
              <div class="treatment-advice">
                <div class="d-flex">
                  <div class="investigation-card">
                    <h2>Diagnosis</h2>
                    <div class="text-body-2">
                      {{
                        Array.isArray(diagnosis)
                          ? diagnosis.join(", ")
                          : diagnosis
                      }}
                    </div>
                  </div>
                  <div class="investigation-card">
                    <h2>Comorbidities</h2>
                    <div class="text-body-2">
                      {{ visit_comorbidities }}
                    </div>
                  </div>
                </div>
                <!--//-->
                <div class="treatment-card">
                  <h2>Treatment Advice</h2>
                  <div class="card">
                    <div v-if="visit_treatment">
                      <h2>{{ visit_treatment }}</h2>
                    </div>
                    <hr v-if="visit_treatment" class="mt-2 mb-2" />
                    <div class="" v-for="drug in prescMeds" :key="drug.key">
                      <h3>
                        {{ drug.drug }} {{ drug.note ? `(${drug.note})` : "" }}
                      </h3>
                      <div class="auto-alignment">
                        <h4>{{ drugStatementEnglish(drug) }}</h4>
                        <p style="direction: rtl">
                          {{ drugStatementUrdu(drug) }}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <!--//-->
                <div
                  class="treatment-card"
                  v-if="Array.isArray(visit_precautions)"
                >
                  <h2>Precautions ( احتیاطی تدابیر)</h2>
                  <div class="card min-height-80">
                    <div
                      class="auto-alignment"
                      v-for="prec in visit_precautions"
                      :key="prec.key"
                    >
                      <h4>{{ prec?.name }}</h4>
                      <p style="direction: rtl">
                        {{ prec?.urdu }}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="custom-footer">
              <p>
                Generated with OneClinic ( https://oneclinic.pk ) | See your medical records at
                https://oneclinic.pk/patient
              </p>
            </div>
          </div>
          <div class="printdiv pt-5 pb-3">
            <v-btn class="print-view-removed" color="primary" @click="print">Print</v-btn>
          </div>
        </v-container>
      </div>
    </div>
  </div>
</template>
  
  <script>
//   import TopBarWhiteBackgroundPatients from "@/components/TopBarWhiteBackgroundPatients.vue";
import moment from "moment";
const canvasWidth = 816;
const canvasHeight = 1056;
let pdfjsLib = window["pdfjs-dist/build/pdf"];
let { get, post } = require("@/apiClient");
import {
  medroutesUrdu,
  dosagesUrdu,
  durationsUrdu,
  timingsUrdu,
  visitFields,
} from "@/config";
import Loading from "@/components/Loading.vue";
export default {
  components: {
    Loading
    //    TopBarWhiteBackgroundPatients,
  },
  name: "PatientRecord",
  data: () => ({
    patientrecords: {},
    loading: false,
    visible: false,
  }),
  mounted() {
    if (!localStorage.getItem("octoken")) {
      this.$router.push({ name: "Patient" });
    }
  },
  methods: {
    print() {
        window.print();
    },
    drugStatementEnglish(pd) {
      let english = ``;
      if (pd.morning) {
        english += `${pd.morning} ${pd.dosage ? pd.dosage : ""} Morning, `;
      }
      if (pd.afternoon) {
        english += `${pd.afternoon} ${pd.dosage ? pd.dosage : ""} Afternoon, `;
      }
      if (pd.evening) {
        english += `${pd.evening} ${pd.dosage ? pd.dosage : ""} Evening, `;
      }
      if (pd.night) {
        english += `${pd.night} ${pd.dosage ? pd.dosage : ""} Night, `;
      }
      //remove last comma
      english = english.substring(0, english.length - 2);
      english += ` - ${
        pd.medfrequency
          ? this.patientrecords.detailitems?.find(
              (x) => x.key === pd.medfrequency
            )?.name
          : ""
      }  `;
      english += ` - ${pd.duration ? pd.duration : ""} ${pd.durationtype} `;
      return english;
    },
    drugStatementUrdu(pd) {
      let uduration = durationsUrdu.find((d) => d.english == pd.durationtype);
      let urdu = `   `;
      let urdudosage = dosagesUrdu.find((d) => d.english == pd.dosage);

      if (urdudosage) {
        urdudosage = urdudosage.urdu;
      } else {
        urdudosage = pd.dosage;
      }
      if (pd.morning) {
        urdu += ` ${pd.morning} ${urdudosage ? urdudosage : ""} ${
          timingsUrdu.morning
        }, `;
      }
      if (pd.afternoon) {
        urdu += `${pd.afternoon} ${urdudosage ? urdudosage : ""} ${
          timingsUrdu.afternoon
        }, `;
      }
      if (pd.evening) {
        urdu += `${pd.evening} ${urdudosage ? urdudosage : ""} ${
          timingsUrdu.evening
        }, `;
      }
      if (pd.night) {
        urdu += `${pd.night} ${urdudosage ? urdudosage : ""} ${
          timingsUrdu.night
        }, `;
      }
      urdu = urdu.substring(0, urdu.length - 2);
      urdu += ` - ${
        pd.medfrequency
          ? this.patientrecords.detailitems?.find(
              (x) => x.key === pd.medfrequency
            ).urdu
          : ""
      }  `;
      urdu += ` - ${pd.duration ? pd.duration : ""} ${
        uduration ? uduration.urdu : pd.durationtype
      } `;
      return urdu;
    },
    formatVisitDate(date) {
      return moment(date).format("Do MMMM YYYY, h:mm a");
    },
    ageFromDate(date) {
      if (!date) return "";
      return moment().diff(date, "years");
    },
    formatDateTime(date) {
      return moment(date).format("DD-MM-YYYY");
    },
    getPatientRecords() {
      this.loading = true
      get("patientaccess/prescription/" + this.$route.params.id).then((res) => {
        // console.log(res);
        this.patientrecords = res.data;
        this.visible = true;
        //   console.log(res);
        // this.patientrecords = res.data;
        // // sort by visit date
        // this.patientrecords.patientvisits.sort(function (a, b) {
        //   var dateA = new Date(a.visitdate),
        //     dateB = new Date(b.visitdate);
        //   return dateB - dateA;
        // });
        this.loading = false
      }).catch((error) => {
        this.loading = false
      });
    },
  },
  computed: {
    patient() {
      return this.patientrecords.detailitems?.find((x) => x.type === "patient");
    },
    visitSummary() {
      return this.patientrecords.prescription;
    },
    visit_history() {
      return this.patientrecords.prescriptiondetail.find(
        (x) => x.type === "visit_history"
      )?.detail || "";
    },
    visit_pasthistory() {
      return this.patientrecords.prescriptiondetail.find(
        (x) => x.type === "visit_pasthistory"
      )?.detail || "";
    },
    visit_examination() {
      return this.patientrecords.prescriptiondetail.find(
        (x) => x.type === "visit_examination"
      )?.detail || "";
    },
    visit_investigations() {
      return this.patientrecords.prescriptiondetail.find(
        (x) => x.type === "visit_investigations"
      )?.detail || "";
    },
    visit_furtherinvestigations() {
      return this.patientrecords.prescriptiondetail.find(
        (x) => x.type === "visit_furtherinvestigations"
      )?.detail || "[]";
    },
    visit_furtherinvestigationsarray() {
      return JSON.parse(
        this.patientrecords.prescriptiondetail.find(
          (x) => x.type === "visit_furtherinvestigationslist"
        )?.detail || "[]"
      ).map(
        (x) => this.patientrecords.detailitems.find((y) => y.key === x).name
      );
    },
    visit_nextvisit() {
      // visit_nextvisit
      return JSON.parse(
        this.patientrecords.prescriptiondetail.find(
          (x) => x.type === "visit_nextvisit"
        )?.detail || "{}"
      );
    },
    diagnosis() {
      // visit_diagnosis
      return JSON.parse(
        this.patientrecords.prescriptiondetail.find(
          (x) => x.type === "visit_diagnosis"
        )?.detail || "[]"
      ).map(
        (x) => this.patientrecords.detailitems.find((y) => y.key === x).name
      );
    },
    visit_comorbidities() {
      // visit_comorbidities
      return JSON.parse(
        this.patientrecords.prescriptiondetail.find(
          (x) => x.type === "visit_comorbidities"
        )?.detail || "[]"
      )
        .map(
          (x) => this.patientrecords.detailitems.find((y) => y.key === x).name
        )
        .join(", ");
    },
    visit_treatment() {
      return this.patientrecords.prescriptiondetail.find(
        (x) => x.type === "visit_treatment"
      )?.detail  || "";
    },
    visit_precautions() {
      // visit_precautions
      return JSON.parse(
        this.patientrecords.prescriptiondetail.find(
          (x) => x.type === "visit_precautions"
        )?.detail || "[]"
      ).map((x) => this.patientrecords.detailitems.find((y) => y.key === x));
    },
    prescMeds() {
      //visit_medicines

    //   console.log(
    //     JSON.parse(
    //       this.patientrecords.prescriptiondetail.find(
    //         (x) => x.type === "visit_medicines"
    //       ).detail
    //     ).map((x) => {
    //       let med = this.patientrecords.drugs.find((y) => y.key === x.medid);
    //       if (med) {
    //         return {
    //           name: med.name,
    //           ...x,
    //         };
    //       } else {
    //         med = this.patientrecords.detailitems.find(
    //           (y) => y.key === x.medid
    //         );
    //         return {
    //           name: med.name,
    //           ...x,
    //         };
    //       }
    //     })
    //   );

      return JSON.parse(
        this.patientrecords.prescriptiondetail.find(
          (x) => x.type === "visit_medicines"
        )?.detail || "[]"
      ).map((x) => {
        let med = this.patientrecords.drugs.find((y) => y.key === x.medid);
        if (med) {
          return {
            drug: med.name,
            ...x,
          };
        } else {
          med = this.patientrecords.detailitems.find((y) => y.key === x.medid);
          return {
            drug: med.name,
            ...x,
          };
        }
      });
    },
    drprofile() {
      return (
        this.patientrecords.detailitems?.find((x) => x.type === "drprofile")
          .profile || {}
      );
    },
    computedCredentials() {
      return (
        this.patientrecords.detailitems
          ?.find((x) => x.type === "drprofile")
          ?.profile.credentials?.split("\n") || []
      );
    },
  },
  created() {
    this.getPatientRecords();
  },
};
</script>
  
  <style scoped>
.print-wrapper {
  display: flex;
  padding: 15px;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.print-content {
  width: 816px;
  max-width: 816px;
  padding: 10px 15px;
  border-radius: 4px;
  border: 1px solid #cecece;
}
.line-height {
  line-height: 14px;
}
.detail-container {
  display: flex;
  padding-bottom: 5px;
  justify-content: space-between;
  border-bottom: 1px solid #e9e9e9;
}
.patient-details {
  min-width: 250px;
  max-width: 250px;
  padding-left: 15px;
}
.logo {
  width: 120px;
}
.doc-details h4 {
  color: #585858;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  margin-bottom: 2px;
}
.doc-details h1 {
  color: #333333;
  font-size: 24px;
  font-weight: 600;
  line-height: 30px;
}
.doc-details span {
  color: #585858;
  font-size: 12px;
}
.doc-details h4 span {
  color: #000000;
  font-size: 12px;
}
.user-detail {
  display: flex;
  color: #585858;
  font-size: 12px;
  line-height: 14px;
  align-items: center;
  margin: 6px 0 6px 0;
  justify-content: space-between;
}
.user-detail span {
  font-weight: 500;
}
.treatment-details {
  display: flex;
  padding-top: 10px;
  position: relative;
}
.complaints-investigation {
  min-width: 250px;
  max-width: 250px;
  padding-right: 10px;
}
.complaints-investigation .investigation-card {
  width: 100%;
  padding: 10px;
  min-height: 65px;
  margin-bottom: 5px;
  border: 1px solid #cecece;
}
.complaints-investigation .investigation-card h2 {
  color: #000000;
  font-size: 12px;
  font-weight: 500;
}
.treatment-advice {
  flex: 1;
  padding-left: 10px;
}
.treatment-advice .investigation-card {
  width: 100%;
  padding: 10px;
  min-height: 70px;
  margin-bottom: 10px;
  border: 1px solid #cecece;
}
.treatment-advice .investigation-card:last-of-type {
  margin-left: 20px;
}
.treatment-advice .investigation-card h2 {
  color: #000000;
  font-size: 12px;
  font-weight: 500;
}
.treatment-card h2 {
  color: #000000;
  font-size: 14px;
  font-weight: 500;
}
.treatment-card .card {
  width: 100%;
  padding: 10px;
  min-height: auto;
  margin-bottom: 10px;
  border: 1px solid #cecece;
}
.treatment-card .card h4 {
  color: #333333;
  font-size: 12px;
  font-weight: 400;
}
.treatment-card .card p {
  font-size: 14px;
  font-weight: 600;
  text-align: right;
  font-family: monospace;
}
.treatment-card .card h3 {
  color: #000000;
  font-size: 12px;
  font-weight: 600;
}
.min-height-80 {
  min-height: 70px !important;
}

.auto-alignment {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}
.auto-alignment p {
  margin: 0 0 0 auto;
}
.custom-footer {
  margin-top: 20px;
  padding-top: 10px;
  max-width: 816px;
  border-top: 1px solid #cecece;
}
.custom-footer p {
  font-size: 14px;
  text-align: center;
}

@media print {
  .calc-height {
    padding-top: 0px;
  }
  printdiv {
    display: none;
  }
  .print-content {
    padding: 0;
    border: none;
  }
  .print-wrapper {
    padding: 0;
  }

  .treatment-advice .investigation-card {
    border: none;
  }
  .treatment-card .card {
    border: none;
  }
  .complaints-investigation .investigation-card {
    border: none;
  }
  .print-wrapper {
    align-items: flex-start;
    /* flex-direction: column; */
  }
  .print-view-removed {
    display: none;
  }
  .custom-footer {
    left: 0;
    right: 0;
    bottom: 0;
    max-width: 100%;
    position: absolute;
    //top: 370px;
    //position: relative;
  }
}
</style>