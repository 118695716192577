<template>
  <div class="content-wrapper">
    <div class="">
      <TopBarWhiteBackgroundPatients />
      <div class="align-box calc-height">
        <v-container class="pt-0 pb-0">
          <Loading
              v-if="loading"
              :isVisible="loading"
              text="Loading Prescriptions"
              class="updated-calc-height"
          />
          <div v-if="!loading">
            <div class="features-box mnw-100 mt-10 mobile-view-space">
              <h3 class="mb-5">{{ patientrecords?.patient?.name }} (Visits)</h3>
            </div>
            <div v-if="patientrecords?.patientvisits?.length > 0">
              <v-table class="table table-striped custom-table-design">
                <thead>
                  <tr>
                    <th>Visit Date</th>
                    <th>Prescription</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(visit, index) in patientrecords?.patientvisits"
                    :key="index"
                  >
                    <td>{{ formatDateTime(visit.visitdate) }}</td>
                    <td>
                      <v-btn
                        color="primary"
                        outlined
                        text
                        @click="
                          $router.push({
                            name: 'PatientPrescription',
                            params: { id: visit.key },
                          })
                        "
                      >
                        View Prescription
                      </v-btn>
                    </td>
                  </tr>
                </tbody>
              </v-table>
            </div>
          </div>
        </v-container>
      </div>
      <!-- file, allow only image or pdf-->
    </div>
  </div>
</template>

<script>
import TopBarWhiteBackgroundPatients from "@/components/TopBarWhiteBackgroundPatients.vue";
import moment from "moment";
import Loading from "@/components/Loading.vue";
const canvasWidth = 816;
const canvasHeight = 1056;
let { get, post } = require("@/apiClient");
export default {
  components: {
    Loading,
    TopBarWhiteBackgroundPatients,
  },
  name: "PatientRecord",
  data: () => ({
    patientrecords: {},
    chatinput: "",
    loading: false,
    chatmgs: [

    ],
  }),
  mounted() {
    if (!localStorage.getItem("octoken")) {
      this.$router.push({ name: "Patient" });
    }
  },
  methods: {
    sendChat() {
      if (this.chatinput) {
        post("/patientaccess/chat/" + this.$route.params.id, {
          msg: this.chatinput,
        }).then((res) => {
          console.log(res);
          this.chatmgs.push(res.data);
          this.chatmgs.sort((a, b) => {
            return b.modified - a.modified;
          });
          this.chatinput = "";
        });
      }
    },
    formatDateTime(date) {
      return moment(date).format("DD-MM-YYYY");
    },
    getPatientRecords() {
      this.loading = true
      get("patientaccess/patientrecord/" + this.$route.params.id).then(
        (res) => {
          //   console.log(res);
          this.patientrecords = res.data;
          // sort by visit date
          this.patientrecords.patientvisits.sort(function (a, b) {
            var dateA = new Date(a.visitdate),
              dateB = new Date(b.visitdate);
            return dateB - dateA;
          });
          this.loading = false
        },
      ).catch((error) => {
        this.loading = false
      });
    },
  },
  created() {
    this.getPatientRecords();
  },
};
</script>

<style scoped>
</style>