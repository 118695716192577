
export const configFormTypes = {
    // medicine: "medicine",
    investigation: "investigation",
    precaution: "precaution",
    diagnosis: "diagnosis",
    comorbidity: "comorbidity",
    dosage: "dosage",
    frequency: "frequency",
    note: "note"
};

export const visitFields = {
    visit_history: "visit_history",
    visit_examination: "visit_examination",
    visit_investigations: "visit_investigations",
    visit_furtherinvestigations: "visit_furtherinvestigations",
    visit_furtherinvestigationslist: "visit_furtherinvestigationslist",
    visit_comorbidities: "visit_comorbidities",
    visit_treatment: "visit_treatment",
    visit_precautions: "visit_precautions",
    visit_diagnosis: "visit_diagnosis",
    visit_medicines: "visit_medicines",
    visit_nextvisittimedistance: "visit_nextvisittimedistance",
    visit_images: "visit_images",
    visit_pasthistory: "visit_pasthistory",
    visit_vitals: "visit_vitals",
    visit_nextvisit: "visit_nextvisit",
    visit_surgicalnotes: "visit_surgicalnotes"
};

export const durations =  [
    'day(s)',
    'week(s)',
    'month(s)',
    'Continuously',
    'When Required',
    'STAT',
    'PRN'
];



export const dosages = [
        'capsule(s)',
    'tablet(s)', 'ml',
    'mg',        'iu',
    'drop',      'tablespoon',
    'teaspoon',  'unit(s)',
    'puff(s)',   'sachet',
    'injection', 'dose step',
    'dropper',   'ml/h',
    'units/kg'
  ]

export const medroutes = [
                  'Oral',
    'Intramuscular',   'Nasal',
    'Intravenous',     'Topical',
    'Intraosseous',    'Intrathecal',
    'Intraperitoneal', 'Intradermal',
    'Nasogastric',     'Sub lingual',
    'Per Rectum',      'Subcutaneous',
    'Per Vaginal',     'Inhalation',
    'Intraoccular'
  ]


export const prescriptionDefaults = [
    {
        key: "default1",
        name: "TabCap 1x1x1",
        medtype: "tabcap",
        defaults: {
            dosage: "tablet(s)",
            morning: 1,
            afternoon: 1,
            evening: 0,
            night: 1,
            route: "Oral",
        }
    },
    {
        key: "tabcapmorning",
        name: "TabCap Morning",
        medtype: "tabcap",
        defaults: {
            dosage: "tablet(s)",
            morning: 1,
            afternoon: 0,
            evening: 0,
            night: 0,
            route: "Oral",
        }
    }
]


export const durationsUrdu = [  {'english': 'day(s)', 'urdu': 'دن کے لئے'},  {'english': 'week(s)', 'urdu': ' ہفتے کے لئے'},  {'english': 'month(s)', 'urdu': 'ماہ کے لئے'},  {'english': 'Continuously', 'urdu': 'مستقل طور پر'},  {'english': 'When Required', 'urdu': 'جب ضرورت ہو'},  {'english': 'STAT', 'urdu': 'فوری'},  {'english': 'PRN', 'urdu': 'ضرورت کی صورت میں'}]
export const dosagesUrdu = [  {'english': 'capsule(s)', 'urdu': 'کیپسول'},  {'english': 'tablet(s)', 'urdu': 'گولی'},  {'english': 'ml', 'urdu': 'ملی لیٹر'},  {'english': 'mg', 'urdu': 'ملی گرام'},  {'english': 'iu', 'urdu': 'بین الاقوامی اکائی'},  {'english': 'drop', 'urdu': 'قطرہ'},  {'english': 'tablespoon', 'urdu': 'ٹیبل سپون'},  {'english': 'teaspoon', 'urdu': 'چائے کا چمچ'},  {'english': 'unit(s)', 'urdu': 'یونٹ(وں)'},  {'english': 'puff(s)', 'urdu': 'پف(وں)'},  {'english': 'sachet', 'urdu': 'ساشے'},  {'english': 'injection', 'urdu': 'انجکشن'},  {'english': 'dose step', 'urdu': 'ڈوز کا اسٹیپ'},  {'english': 'dropper', 'urdu': 'ڈراپر'},  {'english': 'ml/h', 'urdu': 'ملی لیٹر فی گھنٹہ'},  {'english': 'units/kg', 'urdu': 'یونٹس فی کلوگرام'}]
export const medroutesUrdu = [  {'english': 'Oral', 'urdu': 'منہ کے ذریعے'},  {'english': 'Intramuscular', 'urdu': 'عضلہ کے ذریعے'},  {'english': 'Nasal', 'urdu': 'ناک کے ذریعے'},  {'english': 'Intravenous', 'urdu': 'رگ کے ذریعے'},  {'english': 'Topical', 'urdu': 'جلدی کے ذریعے'},  {'english': 'Intraosseous', 'urdu': 'ہڈی میں کے ذریعے'},  {'english': 'Intrathecal', 'urdu': 'نرخ میں کے ذریعے'},  {'english': 'Intraperitoneal', 'urdu': 'پیٹ کے اندر کے ذریعے'},  {'english': 'Intradermal', 'urdu': 'جلد کے اندر کے ذریعے'},  {'english': 'Nasogastric', 'urdu': 'ناک سے پیٹ کے ذریعے'},  {'english': 'Sub lingual', 'urdu': 'زبان کے نیچے کے ذریعے'},  {'english': 'Per Rectum', 'urdu': 'ریکٹم کے ذریعے'},  {'english': 'Subcutaneous', 'urdu': 'تحت الجلدی کے ذریعے'},  {'english': 'Per Vaginal', 'urdu': 'فرجی کے ذریعے'},  {'english': 'Inhalation', 'urdu': 'سانس لینے کے ذریعے'},  {'english': 'Intraoccular', 'urdu': 'آنکھوں کے اندر کے ذریعے'}]
export const timingsUrdu = {
    'morning': 'صبح',
    'afternoon': 'دوپہر',
    'evening': 'شام',
    'night': 'رات'
}

export const drugTypes = [
    'infusion',    'tablet',     'cream',
    'syrup',       'solution',   'gel',
    'capsule',     'sachet',     'spray',
    'lotion',      'drops',      'injection',
    'ointment',    'suspension', 'inhaler',
    'unknown',     'liquid',     'powder',
    'syringe',     'patch',      'evohaler',
    'suppository', 'granules',   'toothpaste',
    'vial',        'lozenges'
  ]

export const visitFollowups = [
    "Days",
    "Weeks",
    "Months",
    "Years"
]

export const medytpepresetsandmedfrequencies = {
    "medtypepresets": [
        {
            "visible": true,
            "linkkey": "saadilyas",
            "muuid": "b4142f30-4d7f-477f-a9e7-11731efd418a",
            "name": "NSAID BD",
            "medfrequency": null,
            "parentid": "saadilyas",
            "medtypes": [
                "tablet"
            ],
            "defaults": {
                "afternoon": 0,
                "dosage": "tablet(s)",
                "medfrequency": "medfrequency_1687415091403",
                "route": "Oral",
                "night": 0,
                "evening": "1",
                "morning": "1"
            },
            "synced": 1,
            "key": "medtypepreset_1687417918292",
            "modified": 1690653913454,
            "type": "medtypepreset"
        },
        {
            "visible": true,
            "linkkey": "saadilyas",
            "muuid": "1603ff91-1777-41c6-a6bb-c653a9d4bbe2",
            "name": "OD",
            "medfrequency": null,
            "parentid": "saadilyas",
            "medtypes": [
                "tablet"
            ],
            "defaults": {
                "afternoon": 0,
                "dosage": "tablet(s)",
                "medfrequency": "medfrequency_1687415091403",
                "route": null,
                "night": 0,
                "evening": 0,
                "morning": "1"
            },
            "synced": 1,
            "key": "medtypepreset_1687501667994",
            "modified": 1687501671823,
            "type": "medtypepreset"
        },
        {
            "visible": true,
            "linkkey": "saadilyas",
            "muuid": "6d8e8e4d-a094-42d1-ab2b-03d495d0d9dc",
            "name": "NSAID BD",
            "medfrequency": null,
            "parentid": "saadilyas",
            "medtypes": [
                "tablet"
            ],
            "defaults": {
                "afternoon": 0,
                "dosage": "tablet(s)",
                "medfrequency": "medfrequency_16946602894260",
                "route": "Oral",
                "night": 0,
                "evening": "1",
                "morning": "1"
            },
            "synced": 1,
            "key": "medtypepreset_16946602894260",
            "modified": 1694666156739,
            "type": "medtypepreset"
        },
        {
            "visible": true,
            "linkkey": "saadilyas",
            "muuid": "6d8e8e4d-a094-42d1-ab2b-03d495d0d9dc",
            "name": "OD",
            "medfrequency": null,
            "parentid": "saadilyas",
            "medtypes": [
                "tablet"
            ],
            "defaults": {
                "afternoon": 0,
                "dosage": "tablet(s)",
                "medfrequency": "medfrequency_16946602894260",
                "route": null,
                "night": 0,
                "evening": 0,
                "morning": "1"
            },
            "synced": 1,
            "key": "medtypepreset_16946602894271",
            "modified": 1694666156742,
            "type": "medtypepreset"
        }
    ],
    "medfrequencies": [
        {
            "visible": true,
            "parentid": "saadilyas",
            "synced": 1,
            "urdu": "ہر روز",
            "linkkey": "saadilyas",
            "key": "medfrequency_1687415091403",
            "muuid": "39ba1cf2-8880-4a29-86fb-7ef7c004bab9",
            "name": "Every Day",
            "modified": 1687415096195,
            "type": "medfrequency"
        },
        {
            "visible": true,
            "parentid": "saadilyas",
            "synced": 1,
            "urdu": "ہر ہفتہ",
            "linkkey": "saadilyas",
            "key": "medfrequency_1687415104947",
            "muuid": "39ba1cf2-8880-4a29-86fb-7ef7c004bab9",
            "name": "Every Week",
            "modified": 1687415106239,
            "type": "medfrequency"
        },
        {
            "visible": true,
            "parentid": "saadilyas",
            "synced": 1,
            "urdu": "ہر مہینے",
            "linkkey": "saadilyas",
            "key": "medfrequency_1687415119397",
            "muuid": "39ba1cf2-8880-4a29-86fb-7ef7c004bab9",
            "name": "Every Month",
            "modified": 1687415121236,
            "type": "medfrequency"
        },
        {
            "visible": true,
            "parentid": "saadilyas",
            "synced": 1,
            "urdu": "ہر جمعہ کو ایک بار",
            "linkkey": "saadilyas",
            "key": "medfrequency_1687417744074",
            "muuid": "1603ff91-1777-41c6-a6bb-c653a9d4bbe2",
            "name": "once, on every friday",
            "modified": 1687417745074,
            "type": "medfrequency"
        },
        {
            "visible": true,
            "parentid": "saadilyas",
            "synced": 1,
            "urdu": "ہر چار گھنٹے بعد ایک گولی",
            "linkkey": "saadilyas",
            "key": "medfrequency_1687417785741",
            "muuid": "1603ff91-1777-41c6-a6bb-c653a9d4bbe2",
            "name": "one pill every 04 hours",
            "modified": 1687417785140,
            "type": "medfrequency"
        }
    ]
}