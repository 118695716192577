<template>
  <div class="content-wrapper">
    <TopBar />
    <div class="content-area">
      <!--Banner Start Here-->
      <div class="home-banner">
        <v-container>
          <h1>
            Find and book the <span>best doctors</span> near you
          </h1>
          <v-row no-gutters>
            <v-col cols="12" sm="5">
              <div class="auto-complete">
                <v-autocomplete
                    label="Enter locality or city"
                    :items="['California', 'Colorado', 'Florida', 'Georgia', 'Texas', 'Wyoming']"
                    variant="solo"
                ></v-autocomplete>
                <v-btn class="detect-btn">
                  <v-icon class="mdi mdi-crosshairs-gps"></v-icon>
                  Detect
                </v-btn>
              </div>
            </v-col>
            <v-col cols="12" sm="7">
              <div class="auto-complete doctors-auto">
                <v-autocomplete
                    label="Search for doctors, hospitals, specialties, services, diseases"
                    :items="['California', 'Colorado', 'Florida', 'Georgia', 'Texas', 'Wyoming']"
                    variant="solo"
                ></v-autocomplete>
                <v-btn class="search-btn" color="secondary">
                  Search
                </v-btn>
              </div>
            </v-col>
          </v-row>
          <div class="people-reviews">
            <span>15 million+ users served</span>
            <svg class="video-icon" color="#006" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <use xlink:href="#video-chat-icon"></use>
            </svg>
          </div>
        </v-container>
      </div>
      <!--Banner End Here-->

      <!--Consultation Start Here-->
      <div class="consultation-box">
        <v-container>
          <v-row gutters="8">
            <v-col cols="12" md="1"></v-col>
            <v-col cols="12" md="5" class="pb-0">
              <div class="card-box">
                <div class="figure">
                  <v-btn class="video-btn" density="compact" color="primary" icon="mdi-video"></v-btn>
                  <img src="../images/oladoc-care-img.svg" alt="svg">
                </div>
                <div class="content">
                  <h4>oladoc Care</h4>
                  <p>
                    Video Consultations with Top <br> Specialists in <strong>Rs. 1000</strong>
                  </p>
                  <v-btn color="secondary" class="secondary-btn">
                    Book Appointment
                    <v-icon class="mdi mdi-chevron-right ml-1"></v-icon>
                  </v-btn>
                </div>
              </div>
            </v-col>
            <v-col cols="12" md="5" class="pb-0">
              <div class="card-box">
                <div class="figure bg-other">
                  <v-btn class="video-btn" density="compact" color="primary" icon="mdi-video"></v-btn>
                  <img src="../images/oladoc-care-img-four.svg" alt="svg">
                </div>
                <div class="content">
                  <h4>
                    Doctors online now
                    <span class="pulsating-circle"></span>
                  </h4>
                  <p>
                    Instant Video Consultation with <br> Top Doctors
                  </p>
                  <v-btn color="primary" class="secondary-btn">
                    Start Consulting
                    <v-icon class="mdi mdi-chevron-right ml-1"></v-icon>
                  </v-btn>
                </div>
              </div>
            </v-col>
            <v-col cols="12" md="1"></v-col>
          </v-row>
        </v-container>
      </div>
      <!--Consultation End Here-->

      <!--Health Concern Start Here-->
      <div class="health-concern">
        <v-container>
          <h2>Find doctors by health concern</h2>
          <div class="health-specialists">
            <a href="javascript:" class="doctors">
              <span>
                <v-icon class="mdi mdi-doctor"></v-icon>
              </span>
              Gynecologist
            </a>
            <!--//-->
            <a href="javascript:" class="doctors">
              <span>
                <v-icon class="mdi mdi-doctor"></v-icon>
              </span>
              Skin Specialist
            </a>
            <!--//-->
            <a href="javascript:" class="doctors">
              <span>
                <v-icon class="mdi mdi-doctor"></v-icon>
              </span>
              Child Specialist
            </a>
            <!--//-->
            <a href="javascript:" class="doctors">
              <span>
                <v-icon class="mdi mdi-doctor"></v-icon>
              </span>
              Orthopedic Surgeon
            </a>
            <!--//-->
            <a href="javascript:" class="doctors">
              <span>
                <v-icon class="mdi mdi-doctor"></v-icon>
              </span>
              ENT Specialist
            </a>
            <!--//-->
            <a href="javascript:" class="doctors">
              <span>
                <v-icon class="mdi mdi-doctor"></v-icon>
              </span>
              Diagnostics
            </a>
            <!--//-->
            <a href="javascript:" class="doctors">
              <span>
                <v-icon class="mdi mdi-doctor"></v-icon>
              </span>
              Diabetes specialist
            </a>
            <!--//-->
            <a href="javascript:" class="doctors">
              <span>
                <v-icon class="mdi mdi-doctor"></v-icon>
              </span>
              Eye Specialist
            </a>
            <!--//-->
          </div>
        </v-container>
      </div>
      <!--Health Concern End Here-->

      <!--Looking For More Start Here-->
      <div class="looking-for-more">
        <v-container>
          <h2>Looking for more?</h2>
          <v-row gutters="12">
            <v-col cols="12" md="4">
              <a href="javascript:" class="doctors">
                <span>
                  <v-icon class="mdi mdi-doctor"></v-icon>
                </span>
                Book Medical Tests
              </a>
            </v-col>
            <v-col cols="12" md="4">
              <a href="javascript:" class="doctors">
                <span>
                  <v-icon class="mdi mdi-doctor"></v-icon>
                </span>
                Read Health Articles
              </a>
            </v-col>
            <v-col cols="12" md="4">
              <a href="javascript:" class="doctors">
                <span>
                  <v-icon class="mdi mdi-doctor"></v-icon>
                </span>
                Ask a Question
              </a>
            </v-col>
          </v-row>
        </v-container>
      </div>
      <!--Looking For More End Here-->

      <!--Book Appointments Start Here-->
      <div class="book-appointments">
        <v-container>
          <h2>Book appointments in 3 easy steps</h2>
          <v-row gutters="12" class="justify-center">
            <v-col cols="12" md="4" class="d-flex justify-center">
              <img src="../images/frame-img.png" alt="Frame">
            </v-col>
            <v-col cols="12" md="6" class="d-flex align-center">
              <div class="appointment-step">
                <p>
                  <v-icon class="mdi mdi-magnify"></v-icon>
                  <strong>Search</strong>
                  for doctors by specialty, hospital, service or disease
                </p>
                <p>
                  <v-icon class="mdi mdi-check-circle-outline"></v-icon>
                  <strong>Select</strong>
                  based on Experience, Fee or Rating
                </p>
                <p>
                  <v-icon class="mdi mdi-medical-bag"></v-icon>
                  <strong>Book</strong>
                  a Confirmed Appointment within Seconds
                </p>
                <div class="pt-4">
                  <v-btn color="primary">Find a doctor</v-btn>
                </div>
              </div>
            </v-col>
          </v-row>
        </v-container>
      </div>
      <!--Book Appointments End Here-->

      <!--Doctors Rating Start Here-->
      <div class="doctors-rating">
        <v-container>
          <v-row gutters="12">
            <v-col cols="12" md="6">
              <h2>Are you a five-star doctor?</h2>
              <p>Sign-up to reach millions of patients</p>
              <ul>
                <li>
                  <v-icon class="mdi mdi-check" color="secondary"></v-icon>
                  Get more appointments through online bookings
                </li>
                <li>
                  <v-icon class="mdi mdi-check" color="secondary"></v-icon>
                  Create and view patient records from anywhere
                </li>
                <li>
                  <v-icon class="mdi mdi-check" color="secondary"></v-icon>
                  Manage your schedule efficiently
                </li>
              </ul>
              <div>
                <v-btn color="primary">Join Now</v-btn>
              </div>
            </v-col>
            <v-col cols="12" md="6">
              <img src="../images/phones.png" alt="Phones">
            </v-col>
          </v-row>
        </v-container>
      </div>
      <!--Doctors Rating End Here-->

      <!--User Recommendations Start Here-->
      <div class="user-recommendations">
        <v-container>
          <h2>Over 200,000 user recommendations</h2>
            <!--Carousel Start Here-->
            <v-carousel hide-delimiters>
              <!--Carousel First Slide Start Here-->
              <v-carousel-item>
                <v-row gutters="12">
                  <v-col cols="12" md="4">
                    <div class="detail-card">
                      <span>
                        <v-icon color="secondary" class="mdi mdi-star"></v-icon>
                        <v-icon color="secondary" class="mdi mdi-star"></v-icon>
                        <v-icon color="secondary" class="mdi mdi-star"></v-icon>
                        <v-icon color="secondary" class="mdi mdi-star"></v-icon>
                        <v-icon color="secondary" class="mdi mdi-star"></v-icon>
                      </span>
                      <p>
                        Great platform, very efficient and works really well on both phone and web.
                        I think this is the most easiest way of booking appointments in Pakistan
                        as it has made the whole process much more efficient.
                      </p>
                    </div>
                    <div class="doctor-avatar">
                      <img src="../images/doctor-one.png" alt="Avatar">
                      <h4>Umer Fayyaz</h4>
                    </div>
                  </v-col>
                  <v-col cols="12" md="4">
                    <div class="detail-card">
                      <span>
                        <v-icon color="secondary" class="mdi mdi-star"></v-icon>
                        <v-icon color="secondary" class="mdi mdi-star"></v-icon>
                        <v-icon color="secondary" class="mdi mdi-star"></v-icon>
                        <v-icon color="secondary" class="mdi mdi-star"></v-icon>
                        <v-icon color="secondary" class="mdi mdi-star"></v-icon>
                      </span>
                      <p>
                        A very helpful app for booking appointments and searching for the
                        required doctors. Has made my life a lot easy. I would strongly recommend
                        this to all
                      </p>
                    </div>
                    <div class="doctor-avatar">
                      <img src="../images/doctor-two.png" alt="Avatar">
                      <h4>Aneeb Ryan</h4>
                    </div>
                  </v-col>
                  <v-col cols="12" md="4">
                    <div class="detail-card">
                      <span>
                        <v-icon color="secondary" class="mdi mdi-star"></v-icon>
                        <v-icon color="secondary" class="mdi mdi-star"></v-icon>
                        <v-icon color="secondary" class="mdi mdi-star"></v-icon>
                        <v-icon color="secondary" class="mdi mdi-star"></v-icon>
                        <v-icon color="secondary" class="mdi mdi-star"></v-icon>
                      </span>
                      <p>
                        Literally the best website to book the appointments online for Doctors.
                        The service is great, helpline guys are very cooperative and understanding.
                        And I don't have to hassle through different hospitals anymore now.
                      </p>
                    </div>
                    <div class="doctor-avatar">
                      <img src="../images/doctor-three.png" alt="Avatar">
                      <h4>Zainab Tariq</h4>
                    </div>
                  </v-col>
                </v-row>
              </v-carousel-item>
              <!--Carousel First Slide End Here-->

              <!--Carousel Second Slide Start Here-->
              <v-carousel-item>
                <v-row gutters="12">
                  <v-col cols="12" md="4">
                    <div class="detail-card">
                      <span>
                        <v-icon color="secondary" class="mdi mdi-star"></v-icon>
                        <v-icon color="secondary" class="mdi mdi-star"></v-icon>
                        <v-icon color="secondary" class="mdi mdi-star"></v-icon>
                        <v-icon color="secondary" class="mdi mdi-star"></v-icon>
                        <v-icon color="secondary" class="mdi mdi-star"></v-icon>
                      </span>
                      <p>
                        The only good healthcare website in Pakistan. The suggested doctors are good
                        and the doctors on the forum ate very responsive too
                      </p>
                    </div>
                    <div class="doctor-avatar">
                      <img src="../images/doctor-two.png" alt="Avatar">
                      <h4>Moin Umar</h4>
                    </div>
                  </v-col>
                  <v-col cols="12" md="4">
                    <div class="detail-card">
                      <span>
                        <v-icon color="secondary" class="mdi mdi-star"></v-icon>
                        <v-icon color="secondary" class="mdi mdi-star"></v-icon>
                        <v-icon color="secondary" class="mdi mdi-star"></v-icon>
                        <v-icon color="secondary" class="mdi mdi-star"></v-icon>
                        <v-icon color="secondary" class="mdi mdi-star"></v-icon>
                      </span>
                      <p>
                        Very helpful staff. Helped me book appointment with my gastroenterologist.
                        I do all my scheduling through oladoc now. Thanks a bunch.
                      </p>
                    </div>
                    <div class="doctor-avatar">
                      <img src="../images/doctor-five.png" alt="Avatar">
                      <h4>Riffat Afzaal</h4>
                    </div>
                  </v-col>
                  <v-col cols="12" md="4">
                    <div class="detail-card">
                      <span>
                        <v-icon color="secondary" class="mdi mdi-star"></v-icon>
                        <v-icon color="secondary" class="mdi mdi-star"></v-icon>
                        <v-icon color="secondary" class="mdi mdi-star"></v-icon>
                        <v-icon color="secondary" class="mdi mdi-star"></v-icon>
                        <v-icon color="secondary" class="mdi mdi-star"></v-icon>
                      </span>
                      <p>
                        Literally the best website to book the appointments online for Doctors.
                        The service is great, helpline guys are very cooperative and understanding.
                        And I don't have to hassle through different hospitals anymore now.
                      </p>
                    </div>
                    <div class="doctor-avatar">
                      <img src="../images/doctor-three.png" alt="Avatar">
                      <h4>Zainab Tariq</h4>
                    </div>
                  </v-col>
                </v-row>
              </v-carousel-item>
              <!--Carousel Second Slide End Here-->
            </v-carousel>
          <div class="google-reviews">
            <img src="../images/google-img.png" alt="Google Icon">
            <span>4.9</span>
            <span>
              <v-icon color="secondary" class="mdi mdi-star"></v-icon>
              <v-icon color="secondary" class="mdi mdi-star"></v-icon>
              <v-icon color="secondary" class="mdi mdi-star"></v-icon>
              <v-icon color="secondary" class="mdi mdi-star"></v-icon>
              <v-icon color="secondary" class="mdi mdi-star"></v-icon>
            </span>
          </div>
            <!--Carousel End Here-->
        </v-container>
      </div>
      <!--User Recommendations End Here-->

      <!--Book Appointments Start Here-->
      <div class="book-appointments bg-change">
        <v-container>
          <v-row gutters="12" class="justify-center">
            <v-col cols="12" md="4" class="d-flex justify-center">
              <img src="../images/frame-img-two.png" alt="Frame">
            </v-col>
            <v-col cols="12" md="6" class="d-flex align-center">
              <div class="appointment-step">
                <h3>Download the oladoc app</h3>
                <p>
                  Search for doctors, specialities, treatments, hospitals and <br> services.
                  Find doctor reviews and book appointment online, <br> all using the oladoc app.
                </p>
                <div class="">
                  <img src="../images/google-play.png" class="w-180" alt="Google Play">
                  <img src="../images/app-store.png" class="w-180" alt="App Store">
                </div>
              </div>
            </v-col>
          </v-row>
        </v-container>
      </div>
      <!--Book Appointments End Here-->

      <!--Brand Logo Wrapper Start Here-->
      <div class="brand-logo-wrapper">
        <v-container>
          <h2>oladoc in news</h2>
          <div class="logos-box">
            <div>
              <img src="../images/dawn.png" alt="Dawn">
              <img src="../images/express-tribune.gif" alt="Tribune">
              <img src="../images/daily-times.png" alt="Daily Time">
              <img src="../images/perspective.png" alt="Perspective">
              <img src="../images/pro-pakistani.png" alt="Pro Pakistani">
              <img src="../images/tech-juice.png" alt="Tech Juice">
            </div>
          </div>
        </v-container>
      </div>
      <!--Brand Logo Wrapper Start Here-->

      <!--Doctors List Start Here-->
      <div class="doctors-list">
        <v-container>
          <v-row gutters="12">
            <!--Doctors in Lahore Start Here-->
            <v-col cols="12" md="3">
              <h2>Doctors in Lahore</h2>
              <ul>
                <li>
                  <a href="javascript:">
                    <v-icon class="mdi mdi-chevron-right"></v-icon>
                    Best Dermatologist in Lahore
                  </a>
                </li>
                <li>
                  <a href="javascript:">
                    <v-icon class="mdi mdi-chevron-right"></v-icon>
                    Best Gynecologist in Lahore
                  </a>
                </li>
                <li>
                  <a href="javascript:">
                    <v-icon class="mdi mdi-chevron-right"></v-icon>
                    Best Urologist in Lahore
                  </a>
                </li>
                <li>
                  <a href="javascript:">
                    <v-icon class="mdi mdi-chevron-right"></v-icon>
                    Best Sexologist in Lahore
                  </a>
                </li>
                <li>
                  <a href="javascript:">
                    <v-icon class="mdi mdi-chevron-right"></v-icon>
                    Best Internal Medicine Specialist in Lahore
                  </a>
                </li>
                <li>
                  <a href="javascript:">
                    <v-icon class="mdi mdi-chevron-right"></v-icon>
                    Best Child Specialist in Lahore
                  </a>
                </li>
                <li>
                  <a href="javascript:">
                    <v-icon class="mdi mdi-chevron-right"></v-icon>
                    Best Orthopedic Surgeon in Lahore
                  </a>
                </li>
                <li>
                  <a href="javascript:">
                    <v-icon class="mdi mdi-chevron-right"></v-icon>
                    Best Eye Specialist in Lahore
                  </a>
                </li>
                <li>
                  <a href="javascript:">
                    <v-icon class="mdi mdi-chevron-right"></v-icon>
                    Best ENT Specialist in Lahore
                  </a>
                </li>
                <li>
                  <a href="javascript:">
                    <v-icon class="mdi mdi-chevron-right"></v-icon>
                    Best Cardiologist in Lahore
                  </a>
                </li>
                <li>
                  <a href="javascript:">
                    <v-icon class="mdi mdi-chevron-right"></v-icon>
                    Best Neurologist in Lahore
                  </a>
                </li>
                <li>
                  <a href="javascript:">
                    <v-icon class="mdi mdi-chevron-right"></v-icon>
                    Best Nephrologist in Lahore
                  </a>
                </li>
                <li>
                  <a href="javascript:">
                    <v-icon class="mdi mdi-chevron-right"></v-icon>
                    Best Psychiatrist in Lahore
                  </a>
                </li>
                <li>
                  <a href="javascript:">
                    <v-icon class="mdi mdi-chevron-right"></v-icon>
                    Best Psychologist in Lahore
                  </a>
                </li>
                <li>
                  <a href="javascript:">
                    <v-icon class="mdi mdi-chevron-right"></v-icon>
                    Best Dentist in Lahore
                  </a>
                </li>
                <li>
                  <a href="javascript:">
                    <v-icon class="mdi mdi-chevron-right"></v-icon>
                    Best Pulmonologist in Lahore
                  </a>
                </li>
                <li>
                  <a href="javascript:">
                    <v-icon class="mdi mdi-chevron-right"></v-icon>
                    Best Gastroenterologist in Lahore
                  </a>
                </li>
              </ul>
            </v-col>
            <!--Doctors in Lahore End Here-->

            <!--Doctors in Karachi Start Here-->
            <v-col cols="12" md="3">
              <h2>Doctors in Karachi</h2>
              <ul>
                <li>
                  <a href="javascript:">
                    <v-icon class="mdi mdi-chevron-right"></v-icon>
                    Best Dermatologist in Karachi
                  </a>
                </li>
                <li>
                  <a href="javascript:">
                    <v-icon class="mdi mdi-chevron-right"></v-icon>
                    Best Gynecologist in Karachi
                  </a>
                </li>
                <li>
                  <a href="javascript:">
                    <v-icon class="mdi mdi-chevron-right"></v-icon>
                    Best Urologist in Karachi
                  </a>
                </li>
                <li>
                  <a href="javascript:">
                    <v-icon class="mdi mdi-chevron-right"></v-icon>
                    Best Sexologist in Karachi
                  </a>
                </li>
                <li>
                  <a href="javascript:">
                    <v-icon class="mdi mdi-chevron-right"></v-icon>
                    Best Internal Medicine Specialist in Karachi
                  </a>
                </li>
                <li>
                  <a href="javascript:">
                    <v-icon class="mdi mdi-chevron-right"></v-icon>
                    Best Child Specialist in Karachi
                  </a>
                </li>
                <li>
                  <a href="javascript:">
                    <v-icon class="mdi mdi-chevron-right"></v-icon>
                    Best Orthopedic Surgeon in Karachi
                  </a>
                </li>
                <li>
                  <a href="javascript:">
                    <v-icon class="mdi mdi-chevron-right"></v-icon>
                    Best Eye Specialist in Karachi
                  </a>
                </li>
                <li>
                  <a href="javascript:">
                    <v-icon class="mdi mdi-chevron-right"></v-icon>
                    Best ENT Specialist in Karachi
                  </a>
                </li>
                <li>
                  <a href="javascript:">
                    <v-icon class="mdi mdi-chevron-right"></v-icon>
                    Best Cardiologist in Karachi
                  </a>
                </li>
                <li>
                  <a href="javascript:">
                    <v-icon class="mdi mdi-chevron-right"></v-icon>
                    Best Neurologist in Karachi
                  </a>
                </li>
                <li>
                  <a href="javascript:">
                    <v-icon class="mdi mdi-chevron-right"></v-icon>
                    Best Nephrologist in Karachi
                  </a>
                </li>
                <li>
                  <a href="javascript:">
                    <v-icon class="mdi mdi-chevron-right"></v-icon>
                    Best Psychiatrist in Karachi
                  </a>
                </li>
                <li>
                  <a href="javascript:">
                    <v-icon class="mdi mdi-chevron-right"></v-icon>
                    Best Psychologist in Karachi
                  </a>
                </li>
                <li>
                  <a href="javascript:">
                    <v-icon class="mdi mdi-chevron-right"></v-icon>
                    Best Dentist in Karachi
                  </a>
                </li>
                <li>
                  <a href="javascript:">
                    <v-icon class="mdi mdi-chevron-right"></v-icon>
                    Best Pulmonologist in Karachi
                  </a>
                </li>
                <li>
                  <a href="javascript:">
                    <v-icon class="mdi mdi-chevron-right"></v-icon>
                    Best Gastroenterologist in Karachi
                  </a>
                </li>
              </ul>
            </v-col>
            <!--Doctors in Karachi End Here-->

            <!--Doctors in Islamabad Start Here-->
            <v-col cols="12" md="3">
              <h2>Doctors in Islamabad</h2>
              <ul>
                <li>
                  <a href="javascript:">
                    <v-icon class="mdi mdi-chevron-right"></v-icon>
                    Best Dermatologist in Islamabad
                  </a>
                </li>
                <li>
                  <a href="javascript:">
                    <v-icon class="mdi mdi-chevron-right"></v-icon>
                    Best Gynecologist in Islamabad
                  </a>
                </li>
                <li>
                  <a href="javascript:">
                    <v-icon class="mdi mdi-chevron-right"></v-icon>
                    Best Urologist in Islamabad
                  </a>
                </li>
                <li>
                  <a href="javascript:">
                    <v-icon class="mdi mdi-chevron-right"></v-icon>
                    Best Sexologist in Islamabad
                  </a>
                </li>
                <li>
                  <a href="javascript:">
                    <v-icon class="mdi mdi-chevron-right"></v-icon>
                    Best Internal Medicine Specialist in Islamabad
                  </a>
                </li>
                <li>
                  <a href="javascript:">
                    <v-icon class="mdi mdi-chevron-right"></v-icon>
                    Best Child Specialist in Islamabad
                  </a>
                </li>
                <li>
                  <a href="javascript:">
                    <v-icon class="mdi mdi-chevron-right"></v-icon>
                    Best Orthopedic Surgeon in Islamabad
                  </a>
                </li>
                <li>
                  <a href="javascript:">
                    <v-icon class="mdi mdi-chevron-right"></v-icon>
                    Best Eye Specialist in Islamabad
                  </a>
                </li>
                <li>
                  <a href="javascript:">
                    <v-icon class="mdi mdi-chevron-right"></v-icon>
                    Best ENT Specialist in Islamabad
                  </a>
                </li>
                <li>
                  <a href="javascript:">
                    <v-icon class="mdi mdi-chevron-right"></v-icon>
                    Best Cardiologist in Islamabad
                  </a>
                </li>
                <li>
                  <a href="javascript:">
                    <v-icon class="mdi mdi-chevron-right"></v-icon>
                    Best Neurologist in Islamabad
                  </a>
                </li>
                <li>
                  <a href="javascript:">
                    <v-icon class="mdi mdi-chevron-right"></v-icon>
                    Best Nephrologist in Islamabad
                  </a>
                </li>
                <li>
                  <a href="javascript:">
                    <v-icon class="mdi mdi-chevron-right"></v-icon>
                    Best Psychiatrist in Islamabad
                  </a>
                </li>
                <li>
                  <a href="javascript:">
                    <v-icon class="mdi mdi-chevron-right"></v-icon>
                    Best Psychologist in Islamabad
                  </a>
                </li>
                <li>
                  <a href="javascript:">
                    <v-icon class="mdi mdi-chevron-right"></v-icon>
                    Best Dentist in Islamabad
                  </a>
                </li>
                <li>
                  <a href="javascript:">
                    <v-icon class="mdi mdi-chevron-right"></v-icon>
                    Best Pulmonologist in Islamabad
                  </a>
                </li>
                <li>
                  <a href="javascript:">
                    <v-icon class="mdi mdi-chevron-right"></v-icon>
                    Best Gastroenterologist in Islamabad
                  </a>
                </li>
              </ul>
            </v-col>
            <!--Doctors in Islamabad End Here-->

            <!--Doctors in Other Cities Start Here-->
            <v-col cols="12" md="3">
              <h2>Doctors in Other Cities</h2>
              <ul>
                <li>
                  <a href="javascript:">
                    <v-icon class="mdi mdi-chevron-right"></v-icon>
                    Best Nephrologist in Multan
                  </a>
                </li>
                <li>
                  <a href="javascript:">
                    <v-icon class="mdi mdi-chevron-right"></v-icon>
                    Best Pulmonologist in Multan
                  </a>
                </li>
                <li>
                  <a href="javascript:">
                    <v-icon class="mdi mdi-chevron-right"></v-icon>
                    Best Cardiologist in Multan
                  </a>
                </li>
                <li>
                  <a href="javascript:">
                    <v-icon class="mdi mdi-chevron-right"></v-icon>
                    Best Neuro Physician in Multan
                  </a>
                </li>
                <li>
                  <a href="javascript:">
                    <v-icon class="mdi mdi-chevron-right"></v-icon>
                    Best Gynecologist in Peshawar
                  </a>
                </li>
                <li>
                  <a href="javascript:">
                    <v-icon class="mdi mdi-chevron-right"></v-icon>
                    Best Urologist in Faisalabad
                  </a>
                </li>
                <li>
                  <a href="javascript:">
                    <v-icon class="mdi mdi-chevron-right"></v-icon>
                    Best Dentist in Faisalabad
                  </a>
                </li>
                <li>
                  <a href="javascript:">
                    <v-icon class="mdi mdi-chevron-right"></v-icon>
                    Best Dermatologist in Faisalabad
                  </a>
                </li>
                <li>
                  <a href="javascript:">
                    <v-icon class="mdi mdi-chevron-right"></v-icon>
                    Best Gynecologist in Gujranwala
                  </a>
                </li>
                <li>
                  <a href="javascript:">
                    <v-icon class="mdi mdi-chevron-right"></v-icon>
                    Best Neurologist in Multan
                  </a>
                </li>
                <li>
                  <a href="javascript:">
                    <v-icon class="mdi mdi-chevron-right"></v-icon>
                    Best Psychiatrist in Faisalabad
                  </a>
                </li>
                <li>
                  <a href="javascript:">
                    <v-icon class="mdi mdi-chevron-right"></v-icon>
                    Best Dermatologist in Gujranwala
                  </a>
                </li>
                <li>
                  <a href="javascript:">
                    <v-icon class="mdi mdi-chevron-right"></v-icon>
                    Best Cardiologist in Faisalabad
                  </a>
                </li>
                <li>
                  <a href="javascript:">
                    <v-icon class="mdi mdi-chevron-right"></v-icon>
                    Best Nutritionist in Faisalabad
                  </a>
                </li>
                <li>
                  <a href="javascript:">
                    <v-icon class="mdi mdi-chevron-right"></v-icon>
                    Best Urologist in Peshawar
                  </a>
                </li>
                <li>
                  <a href="javascript:">
                    <v-icon class="mdi mdi-chevron-right"></v-icon>
                    Best Physiotherapist in Gujranwala
                  </a>
                </li>
                <li>
                  <a href="javascript:">
                    <v-icon class="mdi mdi-chevron-right"></v-icon>
                    Best Gynecologist in Quetta
                  </a>
                </li>
              </ul>
            </v-col>
            <!--Doctors in Other Cities End Here-->
          </v-row>
        </v-container>
      </div>
      <!--Doctors List End Here-->

      <!--Footer Start Here-->
      <div class="footer">
        <v-container>
          <v-row gutters="12">
            <v-col cols="12" md="4">
              <img class="logo" src="../images/logo.png" alt="Logo">
              <p>
                Book appointments with the best Doctors and Specialists such as Gynecologists,
                Skin Specialists, Child Specialists, Surgeons, etc. Avail test services such as MRI,
                CT scan, Ultrasound, X-Ray, etc. and Online Doctor Video Consultations all across
                Pakistan conveniently.
              </p>
              <h2>Company</h2>
              <ul>
                <li><a href="javascript:">About us</a></li>
                <li><a href="javascript:">Privacy policy</a></li>
                <li><a href="javascript:">Delivery Policy</a></li>
                <li><a href="javascript:">Refund Policy</a></li>
                <li><a href="javascript:">Payment Terms</a></li>
                <li><a href="javascript:">Contact us</a></li>
                <li><a href="javascript:">Terms of Use</a></li>
                <li><a href="javascript:">Cancelation Policy</a></li>
                <li><a href="javascript:">FAQs</a></li>
                <li><a href="javascript:">Process</a></li>
              </ul>
            </v-col>
            <v-col cols="12" md="2" class="pl-8">
              <h2>Major Cities</h2>
              <ul>
                <li><a href="javascript:">Karachi</a></li>
                <li><a href="javascript:">Lahore</a></li>
                <li><a href="javascript:">Islamabad</a></li>
                <li><a href="javascript:">Rawalpindi</a></li>
                <li><a href="javascript:">Multan</a></li>
                <li><a href="javascript:">Peshawar</a></li>
                <li><a href="javascript:">Gujranwala</a></li>
                <li><a href="javascript:">Faisalabad</a></li>
                <li><a href="javascript:">Sargodha</a></li>
                <li><a href="javascript:">Bahawalpur</a></li>
                <li><a href="javascript:">Quetta</a></li>
                <li><a href="javascript:">Wah Cantt</a></li>
                <li><a href="javascript:">Hyderabad</a></li>
              </ul>
            </v-col>
            <v-col cols="12" md="3">
              <h2>Top Hospitals</h2>
              <ul>
                <li><a href="javascript:">Doctors Hospital</a></li>
                <li><a href="javascript:">Hameed Latif Hospital</a></li>
                <li><a href="javascript:">National Hospital</a></li>
                <li><a href="javascript:">Fatima Memorial Hospital</a></li>
                <li><a href="javascript:">Omar Hospital & Cardic Centre</a></li>
                <li><a href="javascript:">Ali Medical Centre</a></li>
                <li><a href="javascript:">Shifa International Hospital</a></li>
                <li><a href="javascript:">Quaid-eAzam International Hospital</a></li>
                <li><a href="javascript:">Advanced International Hospital</a></li>
                <li><a href="javascript:">Maroof International Hospital</a></li>
                <li><a href="javascript:">South City Hospital</a></li>
                <li><a href="javascript:">Dr. Ziauddin Hospital(North Nazimabad)</a></li>
                <li><a href="javascript:">Park Lane Hospital</a></li>
                <li><a href="javascript:">National Medical Centre</a></li>
                <li><a href="javascript:">Liaquat National Hospital & Medical College</a></li>
              </ul>
            </v-col>
            <v-col cols="12" md="3">
              <h2>Lab Test</h2>
              <ul>
                <li><a href="javascript:">MRI in lahore</a></li>
                <li><a href="javascript:">X-RAY in lahore</a></li>
                <li><a href="javascript:">CT Scan in lahore</a></li>
                <li><a href="javascript:">Mammography in lahore</a></li>
                <li><a href="javascript:">Ultrasound in lahore</a></li>
              </ul>
              <h2 class="pt-3">More</h2>
              <ul>
                <li><a href="javascript:">Health Blog</a></li>
                <li><a href="javascript:">Forum</a></li>
                <li><a href="javascript:">For Doctors</a></li>
                <li><a href="javascript:">Pharmacy</a></li>
                <li><a href="javascript:">Labs</a></li>
                <li><a href="javascript:">Lab Tests</a></li>
              </ul>
              <h2 class="pt-3 mb-2">Get the oladoc App</h2>
              <div class="">
                <img src="../images/app-store-black.png" alt="App Store">
                <img src="../images/google-play-black.png" alt="Google Play">
              </div>
            </v-col>
          </v-row>
          <!--///-->
          <v-row gutters="12">
            <v-col cols="12" md="3" class="pr-2 pl-2">
              <div class="d-flex align-center verified-doctors">
                <span>
                  <v-icon class="mdi mdi-check-decagram"></v-icon>
                </span>
                <div>
                  <h3>PMC Verified Doctors</h3>
                  <h5>Authentic & updated information</h5>
                </div>
              </div>
            </v-col>
            <v-col cols="12" md="3" class="pr-2 pl-2">
              <div class="d-flex align-center verified-doctors">
                <span>
                  <v-icon class="mdi mdi-weight"></v-icon>
                </span>
                <div>
                  <h3>Money Back Guarantee</h3>
                  <h5>We return money within few hours</h5>
                </div>
              </div>
            </v-col>
            <v-col cols="12" md="3" class="pr-2 pl-2">
              <div class="d-flex align-center verified-doctors">
                <span>
                  <v-icon class="mdi mdi-headset"></v-icon>
                </span>
                <div>
                  <h3>Reliable Customer Support</h3>
                  <h5>7 days a week</h5>
                </div>
              </div>
            </v-col>
            <v-col cols="12" md="3" class="pr-2 pl-2">
              <div class="d-flex align-center verified-doctors">
                <span>
                  <v-icon class="mdi mdi-shield-check"></v-icon>
                </span>
                <div>
                  <h3>Secure Online Payment</h3>
                  <h5>Secure checkout using SSL Certificate</h5>
                </div>
              </div>
            </v-col>
          </v-row>
        </v-container>
      </div>
      <!--Footer End Here-->

      <!--Copy Rights Start Here-->
      <div class="copyright-wrapper">
        <v-container>
          <v-row gutters="12">
            <v-col cols="12" md="8">
              <p>
                Copyright @ 2015 - 2023
                <a href="javascript:">MediConnect Services, a subsidiary of MyDoctor Inc</a>
                - All Rights Reserved Reproduction of material from any oladoc.com pages
                without permission is strictly prohibited.
              </p>
            </v-col>
            <v-col cols="12" md="4" class="d-flex align-center">
              <div class="contact-social-links">
                <span>Connect with us </span>
                <a href="javascript:">
                  <v-icon class="mdi mdi-twitter"></v-icon>
                </a>
                <a href="javascript:">
                  <v-icon class="mdi mdi-facebook"></v-icon>
                </a>
                <a href="javascript:">
                  <v-icon class="mdi mdi-instagram"></v-icon>
                </a>
                <a href="javascript:">
                  <v-icon class="mdi mdi-youtube"></v-icon>
                </a>
                <a href="javascript:">
                  <v-icon class="mdi mdi-email-variant"></v-icon>
                </a>
              </div>
            </v-col>
          </v-row>
        </v-container>
      </div>
      <!--Copy Rights End Here-->
    </div>
  </div>
</template>

<script>

// import { useRoute, useRouter } from "vue-router";
import TopBar from "@/components/TopBar.vue";
export default {
  components: {
    TopBar,
  },
  setup() {},
  name: "Dashboard",
  data: () => ({

  }),
};
</script>

<style scoped>



</style>
