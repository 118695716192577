<template>
  <div>
    <v-app-bar class="cloud-top-nav">
      <v-container class="custom-adjustment pt-0 pb-0 d-flex align-center">
        <div class="d-flex align-center flex-1-1">
          <v-btn icon="$menu" class="show-mobile-view" variant="plain" color="primary" @click.stop="drawer = !drawer"></v-btn>
          <router-link to="/" class="d-flex">
            <v-img class="logo" src="@/assets/images/logo.png" alt=""> </v-img>
          </router-link>
          <ul>
            <li><router-link to="features">Features</router-link></li>
            <li><router-link to="about">About</router-link></li>
            <li><router-link to="contact">Contact</router-link></li>
          </ul>
        </div>
        <div class="d-flex align-center">
          <!-- <v-btn color="primary" variant="elevated">Log In</v-btn> -->
        </div>
      </v-container>
    </v-app-bar>

    <v-navigation-drawer
        v-model="drawer"
        location="top"
        class="home-drawer"
    >
      <v-list density="compact">
        <div class="d-flex align-center justify-space-between pt-1 pb-2 pr-3 pl-5">
          <router-link to="/" class="d-flex">
            <v-img class="logo" src="@/assets/images/logo.png" alt=""> </v-img>
          </router-link>
          <v-btn class="icon-size-32" icon="$close" variant="plain" color="primary" @click.stop="drawer = !drawer"></v-btn>
        </div>
        <v-list-item title="Features" @click="$router.push('/features')" value="features"></v-list-item>
        <v-list-item title="About" @click="$router.push('/about')" value="about"></v-list-item>
        <v-list-item title="Contact" @click="$router.push('/contact')" value="contact"></v-list-item>
      </v-list>
    </v-navigation-drawer>
  </div>
  <!--Show on Mobile View-->
</template>

<script>

export default {
  data: () => ({
    location: 'top',
    drawer: null,
  }),
}

</script>

<style scoped>

.logo {
  width: 220px;
}
.cloud-top-nav {
  padding: 0 !important;
  box-shadow: 0 0 0 0 #cecece !important;
}
.cloud-top-nav:deep(.v-toolbar__content) {
  justify-content: space-between;
}
.cloud-top-nav ul {
  display: flex;
  align-items: center;
  margin: 6px 0 0 80px;
}
.cloud-top-nav ul li {
  list-style: none;
  margin-right: 40px;
}
.cloud-top-nav ul li a {
  color: #121314;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0.5px;
  text-decoration: none;
  text-transform: uppercase;
}
.cloud-top-nav ul li a.router-link-active {
  color: #2f74bf;
}
.cloud-top-nav ul li a:hover {
  color: #2f74bf;
}
.cloud-top-nav .v-btn {
  letter-spacing: 0.3px;
}
.home-drawer {
  display: none;
  top: 0 !important;
  width: 100% !important;
  height: auto !important;
  max-height: fit-content;
  z-index: 1006 !important;
  box-shadow: 0 0 0 0 #cecece;
}
.icon-size-32:deep(.v-btn__content .v-icon) {
  font-size: 32px;
}
.home-drawer .v-list .v-list-item {
  display: flex;
  min-height: auto;
  justify-content: center;
  padding: 15px 15px 12px 15px;
  border-top: 1px solid #f1f1f1;
}
.home-drawer .v-list .v-list-item:deep(.v-list-item-title) {
  color: #000000;
  font-weight: 500;
  letter-spacing: 0.5px;
  text-transform: uppercase;
}
.show-mobile-view {
  display: none;
}
/* Extra Small Media Query Start Here */
@media (min-width: 350px) and (max-width: 600px) {
  .cloud-top-nav {
    padding: 0 !important;
  }
  .logo {
    width: 170px;
  }
  .home-drawer {
    display: inline-block;
  }
  .show-mobile-view {
    display: inline-block;
  }
  .cloud-top-nav ul {
    display: none;
  }
}
/* Extra Small Media Query End Here */

/* Small Media Query Start Here */
@media (min-width: 601px) and (max-width: 960px) {
  .cloud-top-nav {
    padding: 0 !important;
  }
  .home-drawer {
    display: inline-block;
  }
  .show-mobile-view {
    display: inline-block;
  }
  .cloud-top-nav ul {
    display: none;
  }
}
/* Small Media Query End Here */

</style>