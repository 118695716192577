
<template>
  <div></div>
</template>

<script>
export default {
    mounted() {
        localStorage.removeItem("octoken");
        this.$router.push({ name: "Patient" });
    },

}
</script>

<style>

</style>