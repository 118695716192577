<template>
  <div class="content-wrapper">
    <TopBarWhiteBackground />
    <div class="contact-banner">
      <h2>Access Patient Records</h2>
      <div class="form-card">
        <form>
          <v-text-field
            color="primary"
            v-model="formdata.phone"
            label="Phone Number (eg. 030012341233) "
            variant="solo"
          ></v-text-field>
          <v-text-field
            color="primary"
            v-model="formdata.pin"
            type="password"
            label="PIN"
            variant="solo"
          ></v-text-field>
          <div class="d-flex justify-space-between mb-4">
            <v-btn color="primary" @click="login">Login</v-btn>
            <v-btn
              color="primary"
              @click="requestpin"
              variant="plain"
              :disabled="!formdata.phone"
              >Request Pin</v-btn
            >
          </div>
          <div class="d-flex justify-end">
            <!-- install button -->
            <v-btn
              v-if="deferredPrompt"
              color="primary"
              @click="deferredPrompt.prompt()"
              >Install App</v-btn
            >
          </div>
        </form>
      </div>
    </div>
    <!--Banner End Here-->
    <FooterView />
  </div>
</template>

<script>
import TopBarWhiteBackground from "@/components/TopBarWhiteBackground.vue";
import FooterView from "@/components/Footer.vue";
import { post } from "@/apiClient";
export default {
  components: {
    TopBarWhiteBackground,
    FooterView,
  },
  mounted() {
    // if query has token
    window.addEventListener("beforeinstallprompt", (e) => {
      e.preventDefault();
      this.deferredPrompt = e;
    });    
    if (this.$route.query.token) {
      // base64 decode
      let decoded = atob(this.$route.query.token);
      // split by ||
      let split = decoded.split("||");
      this.formdata.phone = split[0];
      this.formdata.pin = split[1];
      this.login();
    }


    if (localStorage.getItem("octoken")) {
      this.$router.push({ name: "PatientRecords" });
    }
  },
  methods: {
    login() {
      post("patientaccess/login", this.formdata).then((res) => {
        if (res.data.errorMessage) {
          alert(res.data.errorMessage);
        } else if (res.data.status === "ok") {
          let token = res.data.token;
          //local storage
          localStorage.setItem("octoken", token);
          this.$router.push({ name: "PatientRecords" });
        }
      });
    },
    requestpin() {
      post("patientaccess/getpin", this.formdata).then((res) => {
        if (res.data.errorMessage) {
          alert(res.data.errorMessage);
        } else {
          alert("Pin sent to your phone");
        }
      }).catch((error) => {
        alert(error.errorMessage);
      })
    },
  },
  data: () => ({
    formdata: {
      phone: "",
      pin: "",
    },
    deferredPrompt: null,

  }),
};
</script>

<style>
</style>