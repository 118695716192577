import { createWebHashHistory, createRouter, createWebHistory } from "vue-router";

// Views
import EduTemplate from "@/views/EdufideTemplate.vue";
import Dashboard from "@/views/Dashboard";
import Home from "@/views/Home.vue";
import FeaturesTemplate from "@/views/FeaturesTemplate.vue";
import About from "@/views/About.vue";
import Contact from "@/views/Contact.vue";
import Patient from "@/views/Patient.vue";
import PatientRecords from "@/views/PatientRecords.vue";
import PatientRecord from "@/views/PatientRecord.vue";
import PatientChat from "@/views/PatientChat.vue";
import PatientFiles from "@/views/PatientFiles.vue";
import PatientPrescription from "@/views/PatientPrescription.vue";
import Logout from "@/views/Logout.vue";
const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/patient",
    name: "Patient",
    component: Patient,
  },
  {
    path: "/patient-records",
    name: "PatientRecords",
    component: PatientRecords,
  },
  {
    path: "/logout",
    name: "Logout",
    component: Logout,
  },
  {
    path: "/patient-records/:id",
    name: "PatientRecord",
    component: PatientRecord,
  },
  {
    path: "/patient-chat/:id",
    name: "PatientChat",
    component: PatientChat,
  },
  {
    path: "/patient-files/:id",
    name: "PatientFiles",
    component: PatientFiles,
  },
  {
    path: "/patient-prescription/:id",
    name: "PatientPrescription",
    component: PatientPrescription,
  },
  {
    path: "/features",
    name: "FeaturesTemplate",
    component: FeaturesTemplate,
  },
  {
    path: "/edu-template",
    name: "EduTemplate",
    component: EduTemplate,
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    component: Dashboard,
  },
  {
    path: "/about",
    name: "About",
    component: About,
  },
  {
    path: "/contact",
    name: "Contact",
    component: Contact,
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
