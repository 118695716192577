<template>
  <div class="">
    <TopBar />
    <!--///-->
    <div class="bg-wrapper">
      <h1>Introducing OneClinic</h1>
      <p>A hyper-efficient collaborative grading tool for online, in-class and paper based assessments</p>
      <div>
        <v-btn>
          Watch Video
        </v-btn>
        <v-btn>
          Book Demo
        </v-btn>
      </div>
    </div>
    <!--///-->
    <section class="content-section">
      <v-container>
        <v-row gutters="8" class="pb-80">
          <v-col md="6">
            <img class="fluid-img" src="../images/educators.jpg" alt="">
          </v-col>
          <v-col md="6">
            <div>
              <h2>Designed for educators by educators</h2>
              <p>
                Assign2 was designed in collaboration with educators at one of top universities in Canada.
                We focused on creating the most efficient assessment grading tool possible. Development of
                new features such as peer-assessment and automated grading continues.
              </p>
            </div>
          </v-col>
        </v-row>
        <!-- // -->
        <v-row gutters="8" class="pb-80">
          <v-col md="6">
            <div>
              <h2>Grading Efficiency</h2>
              <p>
                Assign2 provides a user-friendly grading interface designed to mark large number of exams
                quickly. Exams can be marked with reusable scored comments and rubrics. The grading interface
                can be customised with extensive use of keyboard shortcuts. Rubrics and comments libraries
                can be shared with-in the grading team. Assign2 ensures that grading collisions do not occur
                when multiple graders are marking the same question.
              </p>
            </div>
          </v-col>
          <v-col md="6">
            <img class="fluid-img" src="../images/grading.jpg" alt="">
          </v-col>
        </v-row>
        <!-- // -->
        <v-row gutters="8" class="pb-80">
          <v-col md="6">
            <img class="fluid-img" src="../images/outcomes.jpg" alt="">
          </v-col>
          <v-col md="6">
            <div>
              <h2>Better learning outcomes</h2>
              <p>
                Once graded, assessment can be returned to students for review and feedback.
                Assign2 provides statistics for how the students performed and how well the assessment
                itself was designed. Over time, this leads to better assessments and overall student outcomes.
              </p>
            </div>
          </v-col>
        </v-row>
        <!-- // -->
        <v-row gutters="12" class="pb-80">
          <v-col md="4">
            <div class="flex-wrapper">
              <v-icon large class="mdi mdi-magnify"></v-icon>
              <h2>Student Matching</h2>
              <p>Assign2 matches students automatically to scanned exams, saving valuable grading time</p>
            </div>
          </v-col>
          <v-col md="4">
            <div class="flex-wrapper">
              <v-icon large class="mdi mdi-dots-grid"></v-icon>
              <h2>Multiple Choice Exams</h2>
              <p>
                Assign2 scores multiple choice exams automatically, reducing the need for
                proprietary bubble sheets and scanners
              </p>
            </div>
          </v-col>
          <v-col md="4">
            <div class="flex-wrapper">
              <v-icon large class="mdi mdi-family-tree"></v-icon>
              <h2>Integrated and secure</h2>
              <p>Assign2 integrates with most popular Learning Management Systems over LTI</p>
            </div>
          </v-col>

          <v-col md="4">
            <div class="flex-wrapper">
              <v-icon large class="mdi mdi-currency-usd"></v-icon>
              <h2>Scalable and Economical</h2>
              <p>
                With expensive tools universities have to make tough choices sometimes. Assign2 was
                designed for campus-wide scalability, which costs a fraction of our competitors
              </p>
            </div>
          </v-col>
          <v-col md="4">
            <div class="flex-wrapper">
              <v-icon large class="mdi mdi-chart-bar"></v-icon>
              <h2>Proven Track Record</h2>
              <p>
                Assign2 has been used to grade more than 1.5 million assessment items
              </p>
            </div>
          </v-col>
          <v-col md="4">
            <div class="flex-wrapper">
              <v-icon large>
                mdi-account-group
              </v-icon>
              <h2>Driving Change</h2>
              <p>
                Assign2 supports innovative features such as two-stage group exams, peer-assessment and
                soon automated grading, helping drive better teaching and learning on your campus
              </p>
            </div>
          </v-col>
        </v-row>
        <!--///-->
        <v-row gutters="8">
          <v-col cols="12" md="4">
            <div class="classes-card">
              <div class="header">
                <h2>Free Class</h2>
                <div class="price">
                  <span class="currency">$</span>
                  <span class="amount">0</span>
                  <span class="period">/ Class</span>
                </div>
              </div>
              <div class="content">
                <h3>
                  <v-icon class="mdi mdi-account-group"></v-icon>
                  1 class
                </h3>
                <span>per instructor</span>
                <p>
                  <v-icon class="mdi mdi-swap-horizontal"></v-icon>
                  No LMS integration
                </p>
              </div>
            </div>
          </v-col>
          <v-col cols="12" md="4">
            <div class="classes-card">
              <div class="header">
                <h2>Single Class</h2>
                <h4>Contact us</h4>
              </div>
              <div class="content">
                <h3>
                  <v-icon class="mdi mdi-account-group"></v-icon>
                  per class
                </h3>
                <span>per class</span>
                <p>
                  <v-icon class="mdi mdi-swap-horizontal"></v-icon>
                  LTI integration
                </p>
              </div>
            </div>
          </v-col>
          <v-col cols="12" md="4">
            <div class="classes-card">
              <div class="header">
                <h2>Campus Wide</h2>
                <h4>Contact us</h4>
              </div>
              <div class="content">
                <h3>
                  <v-icon class="mdi mdi-cards-outline"></v-icon>
                  Unlimited usage campus wide
                </h3>
                <span>no limits on usage</span>
                <p>
                  <v-icon class="mdi mdi-swap-horizontal"></v-icon>
                  LMS + LTI integration
                </p>
              </div>
            </div>
          </v-col>
        </v-row>
        <!--///-->
        <p class="published-text">
          Published price structure may change, but will be honoured for existing contracted clients.
          Our price changes for current clients are tied to the US inflation per year.
        </p>
      </v-container>
    </section>
    <!--///-->
    <footer>
      <v-container>
        <v-row gutters="8">
          <v-col cols="12" md="4">
            <p>© 2022 Edufide Inc. All rights reserved.</p>
          </v-col>
          <v-col cols="12" md="8">
            <ul>
              <li><a href="https://www.edufide.com/Edufide_Privacy_Policy.pdf">Privacy Policy</a></li>
              <li><a href="https://www.edufide.com/policy-accessibility">Accessibility Policy</a></li>
              <li><a href="https://www.edufide.com/EdufideTerms.pdf">Terms and Conditions</a></li>
              <li><a href="https://www.edufide.com/contact">Contact</a></li>
            </ul>
          </v-col>
        </v-row>
      </v-container>
    </footer>
  </div>
</template>

<script>

import TopBar from "@/components/TopBar.vue";
export default {
  components: {
    TopBar,
  },
  name: "EduTemplate",
  data: () => ({

  }),
};

</script>

<style></style>