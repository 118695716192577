<template>
  <v-app>
    <v-main>
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
export default {
  name: "App",
  mounted() {
    //scroll to top on route change
    this.$router.afterEach(() => {
      window.scrollTo(0, 0);
    });
    // check if pwa is installed
    window.addEventListener("beforeinstallprompt", (e) => {
      // Prevent Chrome 67 and earlier from automatically showing the prompt
      // console.log("beforeinstallprompt");
      e.preventDefault();
      // // Stash the event so it can be triggered later.
      // this.deferredPrompt = e;
      // // Update UI notify the user they can add to home screen
      // this.showInstallPromotion = true;
    });
    if (window.matchMedia('(display-mode: standalone)').matches) {  
      console.log('display-mode is standalone');
      //redirect to Home
      this.$router.push({ name: "Patient" });
      //print route
      
    } else {
      console.log('display-mode is not standalone');
    }
    console.log(this.$route);
  },
};
</script>

<style scoped>
.v-main {
  padding-top: 0;
  padding-left: 0;
  min-height: 100vh;
}

.v-app-bar {
  padding: 0;
}

@media (min-width: 350px) and (max-width: 767px) {
  .v-main {
    padding-left: 0;
  }

}

@media (min-width: 768px) and (max-width: 1150px) {
  .v-main {
    padding-left: 0;
  }

}
</style>
