<template>
  <div class="content-wrapper">
    <div class="">
      <TopBarWhiteBackgroundPatients />
      <div class="align-box calc-height">
        <v-container class="pt-0 pb-0">
          <div class="features-box mnw-100 mt-10 mb-5 d-flex align-center justify-space-between">
            <h3>Patient Records</h3>
            <v-btn class="h-adjustment" @click="deferredPrompt.prompt()" v-if="deferredPrompt" color="primary">Install App</v-btn>
            <!-- <v-btn color="primary" @click="$router.push('/contact')">Request Demo</v-btn> -->
          </div>
          <!--///-->
          <Loading
              v-if="loading"
              :isVisible="loading"
              text="Patient Records"
              class="updated-calc-height"
          />
          <v-row gutters="12" class="mb-5">
            <v-col cols="12" sm="12" md="12" lg="12" xl="12" 
            v-for="(patient, index) in patients" 
            :key="index"
            >
                <v-card class="mx-auto height-tbv border-color" variant="outlined">
                  <v-card-item>
                    <div>
                      <div class="text-overline mb-1"></div>
                      <div class="d-flex align-baseline">
                        <div class="text-h6 mb-1">{{ patient.patientname }}</div>
                        <div class="text-caption ml-2">
                          {{ patient.doctor  }}
                          <!-- <v-md-preview :text="card.content" /> -->
                        </div>
                      </div>
                    </div>
                  </v-card-item>
                  <v-card-actions  class="pl-4 pr-4 pb-4">
                    <v-btn
                        color="primary"
                        class="primary-btn"
                        @click="$router.push({ name: 'PatientRecord', params: { id: patient.patientid } })"
                    >
                      Prescriptions
                    </v-btn>
                    <v-btn
                        color="primary"
                        class="primary-btn"
                        @click="$router.push({ name: 'PatientFiles', params: { id: patient.patientid } })"
                    >
                        Files
                    </v-btn>
                    <v-btn
                        color="primary"
                        class="primary-btn"
                        @click="$router.push({ name: 'PatientChat', params: { id: patient.patientid } })"
                    >
                        Message
                    </v-btn>
                  </v-card-actions>
                </v-card>
            </v-col>
          </v-row>
        </v-container>
      </div>
    </div>

    <!--Banner End Here-->
  </div>
</template>

<script>
import TopBarWhiteBackgroundPatients from "@/components/TopBarWhiteBackgroundPatients.vue";
import Loading from "@/components/Loading.vue";
let { get } = require("@/apiClient");
export default {
  components: {
    TopBarWhiteBackgroundPatients,
    Loading
  },
  name: "PatientRecords",
  data: () => ({
    patients: [],
    loading: false,
    deferredPrompt: null,
  }),
    methods: {

    },
  mounted() {

    window.addEventListener("beforeinstallprompt", (e) => {
      e.preventDefault();
      this.deferredPrompt = e;
    });       
    if (!localStorage.getItem("octoken")) {
      this.$router.push({ name: "Patient" });
    }
    this.loading = true
    get("patientaccess/getpatients").then((res) => {
      this.patients = res.data.patients;
      this.loading = false
    }).catch((error) => {
      this.loading = false
    });
  },
};
</script>

<style>
</style>