<template>
   <div class="content-wrapper">
     <div class="">
       <TopBarWhiteBackgroundPatients />
       <div class="align-box calc-height">
         <v-container class="pt-0 pb-0">
           <Loading
               v-if="loading"
               :isVisible="loading"
               text="Loading Images"
               class="updated-calc-height"
           />
           <div v-if="!loading">
             <div class="features-box mnw-100 mt-10 mobile-view-space">
               <h3 class="mb-5">{{ patientrecords?.patient?.name }} (Files)</h3>
             </div>
             <!-- side by side images -->
             <div class="d-flex align-center mt-5">
               <h3 class="mr-3">Upload Image</h3>
               <v-btn color="primary" @click="uploadFile">
                 <v-icon class=""> mdi mdi-attachment </v-icon>
               </v-btn>
             </div>
             <div
               v-if="patientrecords?.patientimages?.length > 0"
               class="d-flex flex-wrap justify-center upload-images-box"
             >
               <div
                 v-for="(image, index) in patientrecords?.patientimages"
                 :key="index"
                 class="image-container"
               >
                 Image
                 <v-img :src="image.url" class="mx-auto" max-width="150"> </v-img>
                 <!-- delete button over image -->
                 <v-btn
                   color="primary"
                   fab
                   small
                   dark
                   absolute
                   top
                   right
                   @click="deleteImage(image)"
                 >
                   <v-icon>mdi mdi-delete</v-icon>
                 </v-btn>
               </div>
             </div>
           </div>
         </v-container>
       </div>
       <!-- file, allow only image or pdf-->
       <input
         type="file"
         ref="fileInput"
         style="display: none"
         @change="onFileChange"
         accept="image/*, application/pdf"
       />
     </div>
   </div>
 </template>
 
 <script>
 import TopBarWhiteBackgroundPatients from "@/components/TopBarWhiteBackgroundPatients.vue";
 import moment from "moment";
 import Loading from "@/components/Loading.vue";
 const canvasWidth = 816;
 const canvasHeight = 1056;
 let pdfjsLib = window["pdfjs-dist/build/pdf"];
 let { get, post } = require("@/apiClient");
 export default {
   components: {
     Loading,
      TopBarWhiteBackgroundPatients,
   },
   name: "PatientRecord",
   data: () => ({
     patientrecords: {},
     chatinput: "",
     loading:false,
     chatmgs: [
 
     ],
   }),
   mounted() {
     if (!localStorage.getItem("octoken")) {
       this.$router.push({ name: "Patient" });
     }
     pdfjsLib.GlobalWorkerOptions.workerSrc =
       "https://cdn.jsdelivr.net/npm/pdfjs-dist@2.6.347/build/pdf.worker.min.js";
   },
   methods: {
     sendChat() {
       if (this.chatinput) {
         post("/patientaccess/chat/" + this.$route.params.id, {
           msg: this.chatinput,
         }).then((res) => {
           console.log(res);
           this.chatmgs.push(res.data);
           this.chatmgs.sort((a, b) => {
             return b.modified - a.modified;
           });
           this.chatinput = "";
         });
       }
     },
     onFileChange(e) {
       const file = e.target.files[0];
       console.log(file);
       this.processFile(file);
       //read file
       // const reader = new FileReader();
       // reader.readAsDataURL(file);
       // reader.onload = () => {
       //     // console.log(reader.result);
       // };
       //   this.uploadFileToServer(file);
     },
     async processFile(file) {
       const { type } = file;
       const pages = [];
 
       if (type === "application/pdf") {
         const pdfURL = URL.createObjectURL(file);
         await pdfjsLib
           .getDocument({
             url: pdfURL,
             disableFontFace: this.disableFontFace,
             standardFontDataUrl: "standard_fonts/",
           })
           .promise.then(async (pdf) => {
             // console.log(pdf);
             for (let i = 1; i <= pdf.numPages; i++) {
               await pdf.getPage(i).then((page) => {
                 const c = document.createElement("canvas");
 
                 c.width = canvasWidth;
                 c.height = canvasHeight;
                 const canvasContext = c.getContext("2d");
                 const vPort = page.getViewport({ scale: 1 });
                 let scale = canvasWidth / vPort.width;
                 if (vPort.height * scale > canvasHeight) {
                   scale = canvasHeight / vPort.height;
                 }
                 const viewport = page.getViewport({ scale });
                 const renderContext = {
                   canvasContext,
                   viewport,
                 };
                 page.render(renderContext).promise.then(() => {
                   // put date time on bottom of image
                   canvasContext.font = "12px Arial";
                   canvasContext.fillStyle = "red";
                   canvasContext.fillText(new Date().toLocaleString(), 10, 20);
                   let imagedata = c.toDataURL("image/jpeg");
                   // console.log(imagedata);
                   post("/patientaccess/image/" + this.$route.params.id, {
                     image: imagedata,
                   }).then((res) => {
                     this.patientrecords.patientimages.push(res.data);
                     // console.log(res);
                   });
                 });
               });
             }
           });
       } else if (type.startsWith("image/")) {
         const img = new Image();
         img.src = URL.createObjectURL(file);
         img.onload = () => {
           const c = document.createElement("canvas");
           c.width = canvasWidth;
           c.height = canvasHeight;
           // keep aspect ratio
           const scale = Math.min(
             canvasWidth / img.width,
             canvasHeight / img.height
           );
           const x = canvasWidth / 2 - (img.width / 2) * scale;
           const y = canvasHeight / 2 - (img.height / 2) * scale;
           const ctx = c.getContext("2d");
 
           ctx.drawImage(img, x, y, img.width * scale, img.height * scale);
           // put date time on bottom of image
           ctx.font = "12px Arial";
           ctx.fillStyle = "red";
           ctx.fillText(new Date().toLocaleString(), 10, 20);
           let imagedata = c.toDataURL("image/jpeg");
           // console.log(imagedata);
           post("/patientaccess/image/" + this.$route.params.id, {
             image: imagedata,
           }).then((res) => {
             this.patientrecords.patientimages.push(res.data);
             post("/patientaccess/chat/" + this.$route.params.id, {
                msg: "Image uploaded",
              })
             // console.log(res);
           });
         };
       }
     },
     uploadFile() {
       //   console.log("upload file");
       // open file dialog
       this.$refs.fileInput.click();
     },
     formatDateTime(date) {
       return moment(date).format("DD-MM-YYYY");
     },
     getPatientRecords() {
       this.loading = true
       get("patientaccess/patientrecord/" + this.$route.params.id).then(
         (res) => {
           //   console.log(res);
           this.patientrecords = res.data;
           // sort by visit date
           this.patientrecords.patientvisits.sort(function (a, b) {
             var dateA = new Date(a.visitdate),
               dateB = new Date(b.visitdate);
             return dateB - dateA;
           });
           this.loading = false
         }
       ).catch((error) => {
         this.loading = false
       });
     },
   },
   created() {
     this.getPatientRecords();
   },
 };
 </script>
 
 <style scoped>
 </style>