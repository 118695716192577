import axios from "axios";
const API_CLIENT = axios.create({
  baseURL: process.env.VUE_APP_BASE_URL
});

// request interceptor
const REQUEST_INTERCEPTOR = API_CLIENT.interceptors.request.use(
  function(config) {
    config.headers["Authorization"] = localStorage.getItem("octoken");
    return config;
  },
  function(error) {
    return Promise.reject(error);
  }
);

// response interceptor
const RESPONSE_INTERCEPTOR = API_CLIENT.interceptors.response.use(
  function(response) {
    // Do something with response
    return response;
  },
  function(error) {
    let { response } = error;
    if (response && response.status === 401) {
      // Do logout;
      // Redirect to login;
      return Promise.reject(error.response);
    } else if (error.response) {
      return Promise.reject(error.response);
    }
    return Promise.reject(error);
  }
);

// Note: ejectRequestInterceptors and ejectResponseInterceptors eject interceptors permanently.
function ejectRequestInterceptors() {
  API_CLIENT.interceptors.request.eject(REQUEST_INTERCEPTOR);
}

function ejectResponseInterceptors() {
  API_CLIENT.interceptors.response.eject(RESPONSE_INTERCEPTOR);
}

export { API_CLIENT, ejectRequestInterceptors, ejectResponseInterceptors };
