<template>
  <div class="content-wrapper">
    <div class="banner-nav">
      <TopBarHome />
      <v-container>
        <div class="top-space">
          <h1>Cutting-Edge Electronic <br /> Medical  Records Solution</h1>
          <p>
            A Comprehensive platform designed to revolutionize <br> patient care.
            Streamline your workflow with rapid record <br> input, professional prescription
            generation, supports urdu <br>and english, shortcuts,  secure uploads, and WhatsApp <br>prescription delivery.
            Elevate your practice today!
          </p>
          <v-btn class="request-btn" color="primary" @click="$router.push('/contact')">Request Demo</v-btn>
        </div>
      </v-container>
    </div>
    <!--/// Banner End Here ///-->
    <div class="why-us-wrapper">
      <v-container class="pt-0 pb-0 d-flex">
        <div class="why-us-image">
          <v-img src="@/assets/images/why-us.jpeg" alt=""> </v-img>
        </div>
        <div class="why-us-content">
          <h2>Why US?</h2>
          <p>
            With lightning-fast record input, professional prescription generation, and a suite
            of time-saving features, our product empowers healthcare professionals to provide
            top-tier care efficiently. Simplify your workflow with shortcuts and presets, securely
            upload documents and images, and effortlessly send prescriptions via text, Plus, enhance
            patient engagement by allowing them to access their records and share test results.
          </p>
        </div>
      </v-container>
    </div>
    <!--/// Why Us Wrapper End Here ///-->
    <div class="our-features">
      <v-container>
        <h2>Our Key Features:</h2>
        <v-row gutters="12">
          <v-col cols="12" sm="6" md="4">
            <div class="features-information">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512">
                <path d="M64 96c0-35.3 28.7-64 64-64H512c35.3 0 64 28.7 64 64V352H512V96H128V352H64V96zM0 403.2C0 392.6 8.6 384 19.2 384H620.8c10.6 0 19.2 8.6 19.2 19.2c0 42.4-34.4 76.8-76.8 76.8H76.8C34.4 480 0 445.6 0 403.2zM288 160c0-8.8 7.2-16 16-16h32c8.8 0 16 7.2 16 16v48h48c8.8 0 16 7.2 16 16v32c0 8.8-7.2 16-16 16H352v48c0 8.8-7.2 16-16 16H304c-8.8 0-16-7.2-16-16V272H240c-8.8 0-16-7.2-16-16V224c0-8.8 7.2-16 16-16h48V160z"/></svg>
              <p>
                Lightning-Fast Input of Patient Records & Offline Functionality
              </p>
            </div>
          </v-col>
          <!--///-->
          <v-col cols="12" sm="6" md="4">
            <div class="features-information">
              <svg class="width-40" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512">
                <path d="M64 0C28.7 0 0 28.7 0 64V448c0 35.3 28.7 64 64 64H320c35.3 0 64-28.7 64-64V160H256c-17.7 0-32-14.3-32-32V0H64zM256 0V128H384L256 0zM104 196h72c33.1 0 60 26.9 60 60c0 25.5-15.9 47.2-38.3 55.9l43 40.3 33.8-31c8.1-7.5 20.8-6.9 28.3 1.2s6.9 20.8-1.2 28.3L270 379.7l31.7 29.7c8.1 7.6 8.5 20.2 .9 28.3s-20.2 8.5-28.3 .9l-33.9-31.8-34.9 32c-8.1 7.5-20.8 6.9-28.3-1.2s-6.9-20.8 1.2-28.3l32.6-29.9-64.8-60.8c-.9-.8-1.6-1.7-2.3-2.6H124v44c0 11-9 20-20 20s-20-9-20-20V296 216c0-11 9-20 20-20zm72 80c11 0 20-9 20-20s-9-20-20-20H124v40h52z"/></svg>
              <p>
                Generation of Professional Printable Prescriptions (including urdu) & WhatsApp Delivery
              </p>
            </div>
          </v-col>
          <!--///-->
          <v-col cols="12" sm="6" md="4">
            <div class="features-information">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                <path d="M96 352V96c0-35.3 28.7-64 64-64H416c35.3 0 64 28.7 64 64V293.5c0 17-6.7 33.3-18.7 45.3l-58.5 58.5c-12 12-28.3 18.7-45.3 18.7H160c-35.3 0-64-28.7-64-64zM272 128c-8.8 0-16 7.2-16 16v48H208c-8.8 0-16 7.2-16 16v32c0 8.8 7.2 16 16 16h48v48c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16V256h48c8.8 0 16-7.2 16-16V208c0-8.8-7.2-16-16-16H320V144c0-8.8-7.2-16-16-16H272zm24 336c13.3 0 24 10.7 24 24s-10.7 24-24 24H136C60.9 512 0 451.1 0 376V152c0-13.3 10.7-24 24-24s24 10.7 24 24l0 224c0 48.6 39.4 88 88 88H296z"/></svg>
              <p>
                Create Shortcuts, Presets & Prescription Templates
              </p>
            </div>
          </v-col>
          <!--///-->
          <v-col cols="12" sm="6" md="4">
            <div class="features-information">
              <svg class="width-40" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512">
                <path d="M64 0C28.7 0 0 28.7 0 64V448c0 35.3 28.7 64 64 64H320c35.3 0 64-28.7 64-64V160H256c-17.7 0-32-14.3-32-32V0H64zM256 0V128H384L256 0zM216 408c0 13.3-10.7 24-24 24s-24-10.7-24-24V305.9l-31 31c-9.4 9.4-24.6 9.4-33.9 0s-9.4-24.6 0-33.9l72-72c9.4-9.4 24.6-9.4 33.9 0l72 72c9.4 9.4 9.4 24.6 0 33.9s-24.6 9.4-33.9 0l-31-31V408z"/></svg>
              <p>
                Secure Documents and Image Upload
              </p>
            </div>
          </v-col>
          <!--///-->
          <v-col cols="12" sm="6" md="4">
            <div class="features-information">
              <svg class="width-40" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                <path d="M0 96C0 43 43 0 96 0H384h32c17.7 0 32 14.3 32 32V352c0 17.7-14.3 32-32 32v64c17.7 0 32 14.3 32 32s-14.3 32-32 32H384 96c-53 0-96-43-96-96V96zM64 416c0 17.7 14.3 32 32 32H352V384H96c-17.7 0-32 14.3-32 32zM208 112v48H160c-8.8 0-16 7.2-16 16v32c0 8.8 7.2 16 16 16h48v48c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16V224h48c8.8 0 16-7.2 16-16V176c0-8.8-7.2-16-16-16H272V112c0-8.8-7.2-16-16-16H224c-8.8 0-16 7.2-16 16z"/></svg>
              <p>
                Comprehensive Drug Guide
              </p>
            </div>
          </v-col>
          <!--///-->
          <v-col cols="12" sm="6" md="4">
            <div class="features-information">
              <svg class="width-40" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512">
                <path d="M64 0C28.7 0 0 28.7 0 64V448c0 35.3 28.7 64 64 64H320c35.3 0 64-28.7 64-64V160H256c-17.7 0-32-14.3-32-32V0H64zM256 0V128H384L256 0zM160 240c0-8.8 7.2-16 16-16h32c8.8 0 16 7.2 16 16v48h48c8.8 0 16 7.2 16 16v32c0 8.8-7.2 16-16 16H224v48c0 8.8-7.2 16-16 16H176c-8.8 0-16-7.2-16-16V352H112c-8.8 0-16-7.2-16-16V304c0-8.8 7.2-16 16-16h48V240z"/></svg>
              <p>
                Patient Access to Medical Records
              </p>
            </div>
          </v-col>
          <!--///-->
          <v-col cols="12" sm="6" md="4">
            <div class="features-information">
              <svg class="width-40 color-gray" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                <path d="M128 0c17.7 0 32 14.3 32 32V64H288V32c0-17.7 14.3-32 32-32s32 14.3 32 32V64h48c26.5 0 48 21.5 48 48v48H0V112C0 85.5 21.5 64 48 64H96V32c0-17.7 14.3-32 32-32zM0 192H448V464c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V192zM329 305c9.4-9.4 9.4-24.6 0-33.9s-24.6-9.4-33.9 0l-95 95-47-47c-9.4-9.4-24.6-9.4-33.9 0s-9.4 24.6 0 33.9l64 64c9.4 9.4 24.6 9.4 33.9 0L329 305z"/></svg>
              <p>
                Online Appointment Booking <span>(Upcoming)</span>
              </p>
            </div>
          </v-col>
          <!--///-->
          <v-col cols="12" sm="6" md="4">
            <div class="features-information">
              <svg class="width-40 color-gray" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                 viewBox="0 0 384 512" style="enable-background:new 0 0 384 512;" xml:space="preserve">
                <path d="M16,64C16,28.7,44.7,0,80,0h224c35.3,0,64,28.7,64,64v384c0,35.3-28.7,64-64,64H80c-35.3,0-64-28.7-64-64V64z M144,448
                  c0,8.8,7.2,16,16,16h64c8.8,0,16-7.2,16-16s-7.2-16-16-16h-64C151.2,432,144,439.2,144,448z M304,64H80v320h224V64z"/>
                                <path d="M192,253.5c31.9,0,57.7-25.8,57.7-57.7s-25.8-57.7-57.7-57.7s-57.7,25.8-57.7,57.7S160.1,253.5,192,253.5z M148.7,278.4
                  C115.3,288.2,91,319,91,355.5c0,7.4,6,13.4,13.4,13.4h175.2c7.4,0,13.4-6,13.4-13.4c0-36.5-24.3-67.4-57.7-77.1v22.9
                  c12.4,3.2,21.6,14.5,21.6,28v18c0,4-3.2,7.2-7.2,7.2h-7.2c-4,0-7.2-3.2-7.2-7.2c0-4,3.2-7.2,7.2-7.2v-10.8c0-8-6.4-14.4-14.4-14.4
                  s-14.4,6.4-14.4,14.4v10.8c4,0,7.2,3.2,7.2,7.2c0,4-3.2,7.2-7.2,7.2h-7.2c-4,0-7.2-3.2-7.2-7.2v-18c0-13.4,9.2-24.8,21.6-28v-25.7
                  c-2.7-0.3-5.5-0.4-8.3-0.4h-41.2c-2.8,0-5.5,0.1-8.3,0.4V305c10.4,3.1,18,12.8,18,24.2c0,13.9-11.3,25.2-25.2,25.2
                  s-25.2-11.3-25.2-25.2c0-11.5,7.6-21.1,18-24.2V278.4z M155.9,340.1c6,0,10.8-4.8,10.8-10.8s-4.8-10.8-10.8-10.8
                  s-10.8,4.8-10.8,10.8S150,340.1,155.9,340.1z"/>
                </svg>
              <p>
                Telemedicine/Video Appointments <span>(Upcoming)</span>
              </p>
            </div>
          </v-col>
          <!--///-->
          <v-col cols="12" sm="6" md="4">
            <div class="features-information">
              <svg class="color-gray" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512">
                <path d="M320 368c0 59.5 29.5 112.1 74.8 144H128.1c-35.3 0-64-28.7-64-64V287.6H32c-18 0-32-14-32-32.1c0-9 3-17 10-24L266.4 8c7-7 15-8 22-8s15 2 21 7L522.1 193.9c-8.5-1.3-17.3-1.9-26.1-1.9c-54.7 0-103.5 24.9-135.8 64H320V208c0-8.8-7.2-16-16-16H272c-8.8 0-16 7.2-16 16v48H208c-8.8 0-16 7.2-16 16v32c0 8.8 7.2 16 16 16h48v48c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16zM496 224a144 144 0 1 1 0 288 144 144 0 1 1 0-288zm0 240a24 24 0 1 0 0-48 24 24 0 1 0 0 48zm0-192c-8.8 0-16 7.2-16 16v80c0 8.8 7.2 16 16 16s16-7.2 16-16V288c0-8.8-7.2-16-16-16z"/></svg>
              <p>
                Automated Warnings for Contraindications <span>(Upcoming)</span>
              </p>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </div>
    <!--/// Our Features End Here ///-->
    <div class="try-us-out">
      <v-container class="pt-0 pb-0 d-flex">
        <div class="try-us-content">
          <h2>Try Us Out!</h2>
          <form>
            <v-row gutters="12">
              <v-col cols="12" md="6">
                <v-text-field color="primary" v-model="formdata.name" label="Full Name" variant="solo"></v-text-field>
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field color="primary" v-model="formdata.designation" label="Designation" variant="solo"></v-text-field>
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field type="email" v-model="formdata.email" color="primary" label="Email" variant="solo"></v-text-field>
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field color="primary" v-model="formdata.phone" label="Phone Number" variant="solo"></v-text-field>
              </v-col>
              <div class="w-100 d-flex justify-center">
                <v-col cols="12" md="6" class="d-flex justify-end">
                  <v-text-field v-model="formdata.clinic" color="primary" label="Clinic/Hospital" variant="solo"></v-text-field>
                </v-col>
              </div>
              <v-col cols="12" md="12" class="d-flex justify-center">
                <v-btn color="primary" @click="submitForm">
                  Request Demo
                </v-btn>
              </v-col>
            </v-row>
          </form>
        </div>
        <div class="try-us-image">
          <v-img src="@/assets/images/try-us.jpeg" alt=""> </v-img>
        </div>
      </v-container>
    </div>
    <!--/// Try Us Out End Here ///-->
    <FooterView />
  </div>
</template>

<script>

// import { useRoute, useRouter } from "vue-router";
import TopBarHome from "@/components/TopBarHome.vue";
import FooterView from "@/components/Footer.vue";
import axios from "axios";
export default {
  components: {
    TopBarHome,
    FooterView,
  },
  setup() {},
  name: "Home",
  methods: {
    async submitForm() {
      try {
       let fd = JSON.parse(JSON.stringify(this.formdata));
        console.log(fd);
        axios.post("https://jkp4c7sryk7a4avtalwplid2km0crwoz.lambda-url.us-east-1.on.aws/", fd)
          .then((response) => {
            // console.log(response);
            // reset form
            this.formdata = {
              name: "",
              email: "",
              phone: "",
              designation: "",
              clinic: "",
            };
          })
          .catch((error) => {
            console.log(error);
          });
        // console.log(response);
      } catch (error) {
        console.log(error);
      }
    },
  },
  mounted() {
    console.log("Home");
  },
  data: () => ({
    formdata: {
      name: "",
      email: "",
      phone: "",
      designation: "",
      clinic: "",
      message: "Contact from One Clinic Website",
      subject: "Contact from One Clinic Website",
    },

  }),
};
</script>